import React from 'react';
import styled from 'styled-components';
import Prt1 from '../../assets/prt-pancake.svg';
import Prt2 from '../../assets/prt-haze.png';
import Prt3 from '../../assets/prt-binance.svg';
import Prt4 from '../../assets/prt-fox.svg';
import Prt5 from '../../assets/vosk.jpg';
import Card from '../../components/LandingPage/card';

const Container = styled.div`
  position: relative;
  margin: 0 auto;
  margin-bottom: 15%;
  max-width: 80vw;
  @media screen and (min-width: 1400px) {
    max-width: 80vw;
  }
  @media screen and (min-width: 1100px) {
    margin-bottom: 15%;
    margin-top: 12%;
  }
  @media screen and (max-width: 1100px) {
    margin-bottom: 30%;
    margin-top: 20%;
  }
`;

const Headline = styled.h1`
  color: #fff;
  font-size: 2rem;
  text-align: center;
  margin-bottom: 5%;
  @media screen and (max-width: 800px) {
    margin-bottom: 3%;
  }
`;

const ImageWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  position: relative;
  flex-flow: wrap;
  // @media screen and (max-width: 800px) {
  //   flex-direction: column;
  // }
`;
const Divcard = styled.div`
  width: 100%;
  max-width: 20%;
  img {
    width: 90%;
    display: block;
    margin: auto;
  }
  .imgOuter {
    height: 100%;
    border-radius: 10px;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: Center;
  }
  .voskimg {
    height: 100%;
    // width: auto;
    border-radius: 12px;
  }
  @media screen and (max-width: 800px) {
    max-width: 50%;
    // img {
    //   width: 50%;
    // }
    margin: 10px 0;
  }
`;

function NetworkSection() {
  return (
    <Container
      className="wow fadeInDown"
      data-wow-duration="0.6s"
      data-wow-delay="0.8s"
    >
      <Headline>Our Network</Headline>
      <ImageWrapper>
        <Divcard>
          <img src={Prt1} />
        </Divcard>
        <Divcard>
          <a href="https://hazecrypto.net/audit/hbsc" target="_blank">
            <div className="imgOuter">
              <img src={Prt2} />
            </div>
          </a>
        </Divcard>
        <Divcard>
          <img src={Prt3} />
        </Divcard>
        <Divcard>
          <img src={Prt4} />
        </Divcard>
        <Divcard>
          <a
            href="https://www.youtube.com/watch?v=cY6wI7EuiIk&t=701s"
            target="_blank"
          >
            <img src={Prt5} className="voskimg" />
          </a>
        </Divcard>
      </ImageWrapper>
    </Container>
  );
}

export default NetworkSection;
