import React from 'react';
import {
  withStyles,
  createStyles,
  WithStyles,
  Theme,
} from '@material-ui/core/styles';
import { MyTheme } from 'theme';
import { Box } from '@material-ui/core';
import cardBg from 'assets/cardBg2.png';
import cardBgBig from 'assets/cardBgBig.png';
import cardBgBig2 from 'assets/cardBgBig2.png';
import infoCardBg from 'assets/infoCardBg.png';

type ClassKey = 'root' | 'isYellowclass';

const styles = (theme: Theme) => {
  const myTheme = theme as MyTheme;
  return createStyles({
    root: {
      padding: myTheme.spacing(4),
      backgroundColor: myTheme.palette.background.paper,
      borderRadius: '8px',
      position: 'relative',
      '& img': {
        display: 'block',
      },
    },
    isYellowclass: {
      padding: myTheme.spacing(4),
      backgroundColor: '#f3ba2f',
      borderRadius: '8px',
      position: 'relative',
    },
  });
};

type FormCardProps = WithStyles<ClassKey> & {
  children?: React.ReactElement;
  isTileOnRight?: boolean;
  isYellow?: boolean;
  isbigcard?: boolean;
  isbigcard2?: boolean;
};

const FormCardView = (props: FormCardProps) => {
  const {
    classes,
    isTileOnRight = false,
    isYellow = false,
    isbigcard = false,
    isbigcard2 = false,
    children,
    ...others
  } = props;

  return (
    <Box
      className={!isYellow ? classes.root : classes.isYellowclass}
      {...others}
    >
      {isTileOnRight ? (
        <Box position="absolute" left="30%" bottom="0" height="80%">
          <img src={infoCardBg} height="100%" />
        </Box>
      ) : (
        <Box position="absolute" right="0%" bottom="0" height="100%">
          <img
            src={isbigcard ? cardBgBig : isbigcard2 ? cardBgBig2 : cardBg}
            height="100%"
            style={
              isbigcard || isbigcard2
                ? { marginLeft: 'auto' }
                : { marginLeft: '40%' }
            }
          />
        </Box>
      )}

      {children}
    </Box>
  );
};

export const FormCard = withStyles(styles)(FormCardView);

export default FormCard;
