import React from 'react';
import styled from 'styled-components';
import Modal from 'react-modal';
import Navbar from '../../components/LandingPage/navbar';
import BackgroundTop from '../../assets/LandingAssets/top/coming.png';
import BackgroundTopMobile from '../../assets/LandingAssets/top/top-bg-mobile.png';
import Astro from '../../assets/LandingAssets/top/astro.png';
import LogoImgge from '../../assets/logo.png';
import Play from '../../assets/LandingAssets/top/play.svg';
import Button from '../../components/LandingPage/button';
import Card from '../../components/LandingPage/card';
import StakeImg from '../../assets/LandingAssets/about/stake.png';
import BackendImg from '../../assets/LandingAssets/about/backend.png';
import CompoundImg from '../../assets/LandingAssets/about/compound.png';
import { themeColor } from '../../theme';
import { Link } from 'react-router-dom';
import Header from '../../layout/Header';

const ContainerHeight = styled.div`
  position: relative;
  min-height: calc(100vh - 97px);
  display: flex;
  width: 100%;
  align-items: flex-end;
  .comingsoon {
    background: transparent;
    top: 0;
    width: 100%;
    position: absolute;
    z-index: 9999;
  }
`;

const HeaderOuter = styled.div`
  top: 0;
  width: 100%;
  position: absolute;
  z-index: 9999;
  .comingsoonheader {
    background: transparent;
    // padding: 14px 0;
    .connectBtn {
      background: #fff;
    }
  }
`;

const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(${BackgroundTop});
  // background-size: 120vw;
  background-repeat: no-repeat;
  // background-position: -3rem 10rem;
  background-size: contain;
  background-position: top right;
  @media screen and (min-width: 1200px) {
    background-size: 100vw;
  }
  @media screen and (max-width: 768px) {
    background-image: url(${BackgroundTopMobile});
    background-position: top right;
  }
  // @media screen and (min-width: 1400px) {
  //   background-size: cover;
  // }
`;

const CenterText = styled.h3`
  color: #fff;
  text-decoration: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 44px;
  font-weight: 700;
`;
const Astroimg = styled.img`
  width: 15vh;
  height: auto;
  position: absolute;
  z-index: 0;
  left: 30%;
  top: 50%;
  max-width: 140px;
  transition: all 1s ease;
  z-index: 1;
  @media screen and (max-width: 700px) {
    top: 20%;
    max-width: 80px;
  }
`;

const LogoImg = styled.img`
  width: 15vh;
  height: auto;
  position: absolute;
  z-index: 0;
  left: 4rem;
  top: 2rem;
  max-width: 140px;
  transition: all 1s ease;
  z-index: 99;
`;

function TopSection() {
  const bgref = React.useRef<HTMLImageElement>(null);

  function onMouseEnterHandler(e) {
    if (bgref.current) {
      if (e.movementX > 50) {
        e.movementX = 50;
      }
      if (e.movementY > 5) {
        e.movementY = 5;
      }
      bgref.current.style.setProperty(
        'transform',
        `translate(${-e.movementX * 3}px ,${-e.movementY * 20}px)`,
      );
    }
  }

  return (
    <ContainerHeight onMouseMove={(e) => onMouseEnterHandler(e)}>
      {/* <HeaderOuter>
        <Header pageName="comingsoon" className="comingsoonheader" />
      </HeaderOuter> */}
      {/* <LogoImg src={LogoImgge} /> */}
      <Astroimg src={Astro} ref={bgref} />
      <Background />
      <CenterText>Coming Soon</CenterText>
      {/* <FooterSection /> */}
    </ContainerHeight>
  );
}

export default TopSection;
