import React from 'react';
import styled from 'styled-components';
import TwitterIcon from '../../assets/LandingAssets/contact/twitter.svg';
import TgIcon from '../../assets/LandingAssets/contact/tg.svg';
import YtIcon from '../../assets/LandingAssets/contact/yt.svg';
import DiscordIcon from '../../assets/LandingAssets/contact/discord.svg';
import GithubIcon from '../../assets/LandingAssets/contact/github.svg';

import { themeColor } from '../../theme';

const Container = styled.div`
  padding: 10rem 1.5rem 1.5rem;
  position: relative;
  margin-top: -20vh;
  margin-bottom: 10rem;

  @media screen and (min-width: 1024px) {
    margin-top: -10vh;
  }

  @media screen and (orientation: landscape) {
    margin-top: -30vh;
  }

  @media screen and (min-width: 1400px) {
    margin-bottom: 14rem;
    margin-top: -20vh;
  }
`;

const Title = styled.h1`
  font-size: 1.6rem;
  color: white;
  font-weight: 400;
  text-align: center;
  margin-bottom: 0;
`;

const Text = styled.p`
  color: white;
  font-size: 0.8rem;
  text-align: center;
  margin: 40px auto;

  @media screen and (orientation: landscape) {
    max-width: 50vw;
    line-height: 1.8;
  }

  @media screen and (min-width: 768px) {
    max-width: 50vw;
  }

  @media screen and (min-width: 1200px) {
    max-width: 35vw;
    line-height: 1.8;
  }
`;

const SocialWrapper = styled.div`
  // margin-top: 4rem;
  display: flex;
  justify-content: center;
`;

const SocialLink = styled.a`
  transition: all 0.3s ease;
  &:first-child {
    img {
      transform: Scale(2) translateY(-1px);
      transform-origin: center;
    }
  }
  &:nth-child(3) {
    img {
      transform: Scale(1.4) translateY(-1px);
      transform-origin: center;
    }
  }
  &:last-child {
    img {
      transform: Scale(1.1) translateY(0px);
      transform-origin: center;
    }
  }
  &:hover {
    transform: Scale(1.1);
  }
`;

const SocialIcon = styled.img`
  margin: 0 1rem;
  height: 3rem;
  width: auto;
  color: ${themeColor.primary};
  &: @media screen and (min-width: 1200px) {
    height: 4rem;
    width: auto;
  }
  @media screen and (max-width: 450px) {
    height: 2rem;
  }
`;

function ContactSection() {
  return (
    <Container
      className="wow fadeInDown"
      data-wow-duration="0.6s"
      data-wow-delay="0.3s"
    >
      <Title>Contact the HBSC Team</Title>
      <Text>
        To learn more, ask further questions to the team and engage in community{' '}
        <br />
        discussions, you can follow the HBSC project on Telegram, Github,
        Youtube and Twitter.
      </Text>
      <SocialWrapper>
        <SocialLink href="https://t.me/HBSC_IO" target="_blank">
          <SocialIcon src={TgIcon} />
        </SocialLink>
        <SocialLink
          href="https://youtube.com/channel/UCFqMt-naLQNKioZ-7dHue2Q"
          target="_blank"
        >
          <SocialIcon src={YtIcon} />
        </SocialLink>
        <SocialLink href="https://discord.gg/ba4SNDAhRU" target="_blank">
          <SocialIcon src={DiscordIcon} />
        </SocialLink>
        <SocialLink href="https://twitter.com/hbsc_io" target="_blank">
          <SocialIcon src={TwitterIcon} />
        </SocialLink>
        <SocialLink
          href="https://github.com/HXY-EcoSystem/HXY-Development"
          target="_blank"
        >
          <SocialIcon src={GithubIcon} />
        </SocialLink>
      </SocialWrapper>
    </Container>
  );
}

export default ContactSection;
