import React from 'react';
import styled from 'styled-components';
import AboutBg from '../../assets/LandingAssets/about/about-bgg.png';

const Container = styled.div`
  position: relative;
  margin: 0 auto;
  min-height: 100vh;
  background-image: url(${AboutBg});
  background-size: 100%;
  background-repeat: no-repeat;
  background-color: #f6ce6b;
  padding-bottom: 5rem;
  overflow-x: hidden;
  padding-left: 7vw;
  padding-right: 7vw;
  @media screen and (min-width: 1200px) {
    padding-left: 9vw;
    padding-right: 9vw;
  }
`;

const ContainerWidth = styled.div`
  position: relative;
  padding: 2rem;
  overflow: hidden;
  z-index: 1;

  @media screen and (min-width: 1200px) {
    padding: 1rem;
  }

  @media screen and (min-width: 1400px) {
    padding: 1rem;
  }
`;

const Headline = styled.h1`
  margin-bottom: 8rem;
  margin-top: 13rem;
  @media screen and (max-width: 768px) {
    margin-bottom: 4rem;
    margin-top: 6rem;
  }
`;

const CardWrapper = styled.div``;
const CardRow = styled.div`
  background: #fff;
  color: #000;
  border-radius: 10px;
  padding: 26px 26px;
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0px;
  }
`;
const Title = styled.h3`
  font-size: 18px;
  margin-bottom: 20px;
  font-weight: 600;
`;
const Text = styled.div`
  font-size: 16px;
`;

function AboutSection() {
  return (
    <Container
      className="wow fadeInDown"
      data-wow-duration="0.6s"
      data-wow-delay="0.8s"
    >
      <ContainerWidth>
        <Headline>Key Features</Headline>
        <CardWrapper>
          <CardRow>
            <Title>Total Staked HBSC supply inflates at 3.5% of P/A</Title>
            <Text>
              The HBSC total staked supply inflates at 3.5% divided by 4 tiers
              the longer you stake for the higher APY you earn per tier
            </Text>
          </CardRow>
          <CardRow>
            <Title>90% burn feature of early end stake penalties</Title>
            <Text>
              To ensure good Tokenomics and game theory 90% of all early end
              staked HBSC is burned potentially creating the ability to have a
              deflationary supply as well as an inflationary supply this is
              called dual Tokenomics and could end up being one of themajor key
              features of the HBSC product
            </Text>
          </CardRow>
          <CardRow>
            <Title>Low cap 10K initial supply</Title>
            <Text>
              Initial HBSC supply starts at 10000 HBSC depending on how the
              early end stakes play out the supply could either be higher or
              lower than this number but should ensure HBSC remains a low cap
              gem
            </Text>
          </CardRow>
          <CardRow>
            <Title>Continual buy-backs</Title>
            <Text>
              The HBSC team will perform continuous buy backs of HBSC from the
              main liquidity pool supported from surrounding ecosystem products
            </Text>
          </CardRow>
          <CardRow>
            <Title>
              4 staking duration tiers one with an instant unstake feature
            </Title>
            <Text>
              HBSC staking consists of 4 main tiers one of which has an instant
              unstake feature but remains the lowest APY. The longest stake
              duration is the 365 day stake and has the highest 1.75% APY rate
              of all tiers other tiers respectively follow the same trend line
            </Text>
          </CardRow>

          <CardRow>
            <Title>20 day fair reservation launch phase</Title>
            <Text>
              Binance Smart Chain is well known for having lower gas fees than
              Ethereum and this key feature should allow for a much smoother
              running product where all sizes of investors are included
            </Text>
          </CardRow>
          <CardRow>
            <Title>Low transaction fees on BSC</Title>
            <Text>
              Binance Smart Chain is well known for having lower gas fees than
              Ethereum and this key feature should allow for a much smoother
              running product where all sizes of investors are included
            </Text>
          </CardRow>
          <CardRow>
            <Title>Completely decentralised & fully audited contracts</Title>
            <Text>
              Contracts are fully audited by Haze Crypto with the reports
              available online. There are no Admin keys for HBSC. Aft er the
              reservation phase has finished and the call for the liquidity push
              is triggered HBSC is 100% complete and 100% decentralised
            </Text>
          </CardRow>
          <CardRow>
            <Title>No founders fees or team Tokens</Title>
            <Text>
              A key feature of HBSC is there are zero founders fees or team
              tokens from the initial launch of HBSC the only way any of the
              team will be compensated is from the 10% HBSC that are not burned
              from early end stakes
            </Text>
          </CardRow>
          <CardRow>
            <Title>Stable liquidity Pool backed by BUSD</Title>
            <Text>
              Although crypto is exciting and volatile it can also be painful to
              watch lots of value instantly disappear this is why HBSC’s main
              liquidity pool will be back with a stable token the main pair is
              HBSC/BUSD
            </Text>
          </CardRow>
          <CardRow>
            <Title>Liquidity locked every 365 days</Title>
            <Text>
              The initial Liquidity will be retrospectively locked every 365
              days to ensure we can remain on the major dex’s but with the
              flexibility to migrate if the market conditions change
            </Text>
          </CardRow>
          <CardRow>
            <Title>Claimable daily liquid dividends</Title>
            <Text>
              One of the main key features of HBSC are the liquidity dividends
              that build up in real-time and be claimed at any time. This
              separates us from some other staking projects as gains can only be
              realised aft er stakes finish
            </Text>
          </CardRow>
        </CardWrapper>
      </ContainerWidth>
    </Container>
  );
}

export default AboutSection;
