import React, { useEffect, useState, useCallback } from 'react';
import {
  withStyles,
  createStyles,
  WithStyles,
  Theme,
} from '@material-ui/core/styles';
import { Grid, Box, Typography, Container } from '@material-ui/core';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import stakingTitle from 'assets/stakingTitle.png';
import lockIcon from 'assets/lockIcon.png';
import walletIcon from 'assets/walletIcon.svg';
import FormCard from 'components/StakingPage/FormCard';
import OutlinedInput from 'components/StakingPage/OutlinedInput';
import cardBg from 'assets/cardBg2.png';
import { Header } from 'layout/Header';
import Modal from 'react-modal';
import Countdown from 'react-countdown';
import ReactTooltip from 'react-tooltip';
import { useQuery, gql } from '@apollo/client';
import {
  web3,
  stakingAddress,
  stakingAbi,
  stakingContract,
  hbscContract,
  busdContract,
} from '../../contracts';

const emergencyUnstakeWarning =
  'WARNING: Unstaking before stake period has finished could incur a penalty. <br/> Please refer to whitepaper for more information.';
const stakeOnTopWarning =
  'WARNING: Staking on top of an existing stake will reset the stake timer.';

const MarkIcon = () => {
  return (
    <svg width="25" height="25" viewBox="0 0 52 56" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.4 55.64L0.47998 21.162L21.8101 36.022L51.4 0.60199L22.4 55.64Z"
        fill="#18FF00"
      />
    </svg>
  );
};

import { MyTheme } from 'theme';

type ClassKey = 'root' | 'pageTitle' | 'icon' | 'attentionModal' | 'cards';

const Hbscwallet = styled.div`
  display: flex;
  justify-content: space-between;
  button {
    background: #fff;
    color: #000;
  }
`;

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '0px',
    background: 'transparent',
    border: 0,
    maxWidth: '600px',
    width: '80%',
    minHeight: '400px',
  },
};
const styles = (theme: Theme) => {
  const myTheme = theme as MyTheme;
  return createStyles({
    root: {},
    attentionModal: {
      background: '#272727',
      color: '#fff',
      textAlign: 'center',
      padding: '40px 24px',
      borderRadius: '12px',
      position: 'relative',
      '& .content': {
        marginBottom: '24px',
      },
      '& .heading': {
        marginBottom: '20px',
      },
      '& .MuiButton-textPrimary': {
        color: '#fff',
        marginTop: '14px',
        fontSize: '12px',
        zIndex: 5,
      },
      '& .MuiButton-contained': {
        zIndex: 5,
      },
      '& .cardbg': {
        position: 'absolute',
        width: '50%',
        left: '50%',
        transform: 'translateX(-50%)',
        bottom: 0,
        zIndex: 0,
      },
    },
    pageTitle: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      paddingTop: 36,
      paddingBottom: 34,
      color: myTheme.palette.primary.main,
      [theme.breakpoints.down(500)]: {
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
      },
      '& .stakingheadingOuter': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        position: 'relative',
      },
      '& h2': {
        margin: '0 0 -7px 0',
        fontSize: 46,
        [theme.breakpoints.down('sm')]: {
          fontSize: 28,
        },
        [theme.breakpoints.down(500)]: {
          fontSize: 22,
          marginBottom: 2,
        },
      },
      '& b': {
        color: '#000',
      },
      '& p': {
        margin: '0',
        fontSize: 17,
        // color: myTheme.palette.text.secondary,
        color: '#000',
        [theme.breakpoints.down(500)]: {
          fontSize: 14,
        },
      },
      '& .leftOuter': {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        '& .MuiTypography-root': {
          width: '100%',
        },
      },
      '& .mycountdown': {
        '& span': {
          fontWeight: 500,
        },
        color: '#000',
        fontWeight: 600,
        fontSize: '18px',
        background: '#fff',
        padding: '0 14px',
        [theme.breakpoints.down('sm')]: {
          fontSize: '14px',
        },
        [theme.breakpoints.down(500)]: {
          marginLeft: 62,
          marginTop: 8,
        },
      },
    },
    icon: {
      display: 'block',
      width: 60,
      height: 60,
      marginTop: 5,
      marginRight: 22,
      background: `url(${stakingTitle})`,
      backgroundSize: 'cover',
      [theme.breakpoints.down('sm')]: {
        width: 40,
        height: 40,
      },
    },
    cards: {
      marginBottom: 40,
      [theme.breakpoints.down(500)]: {
        '& .leftbuttons': {
          '& button': {
            marginTop: '4px',
            marginBottom: '4px',
          },
        },
      },
      '& ul': {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        listStyle: 'none',
        margin: 0,
        padding: 0,
      },
      '& li': {
        width: '280px',
        marginBottom: 30,
        padding: '0 15px',
      },
    },
  });
};

type StakingProps = WithStyles<ClassKey>;

// Random component
const Completionist = () => <span>You are good to go!</span>;

// Renderer callback with condition
const renderer = ({ days, hours, minutes, seconds, completed }) => {
  if (completed) {
    // Render a completed state
    return <Completionist />;
  } else {
    // Render a countdown
    return (
      <div className="mycountdown">
        Day {days} &nbsp;
        <span>
          {hours}:{minutes}:{seconds}
        </span>{' '}
      </div>
    );
  }
};


const BITQUERY = gql`
  {
    ethereum(network: bsc) {
      dexTrades(
        buyCurrency: {in: "0x1a8abcfdf145379c2443eb7a6e3d127186c867db"}
      ) {
        last_price: maximum(of: block, get: price)
      }
      transfers(date: {since: null, till: null}, amount: {gt: 0}) {
        burned: amount(
          calculate: sum
          receiver: {in: ["0x0000000000000000000000000000000000000000", "0x0000000000000000000000000000000000000001", "0x000000000000000000000000000000000000dead"]}
        )
        minted: amount(
          calculate: sum
          sender: {in: ["0x0000000000000000000000000000000000000000"]}
        )
        currency(currency: {is: "0x1a8abcfdf145379c2443eb7a6e3d127186c867db"}) {
          symbol
          name
          address
        }
      }
      address(address: {is: "0x1a8abcfdf145379c2443eb7a6e3d127186c867db"}) {
        smartContract {
          attributes {
            name
            type
            address {
              address
              annotation
            }
            value
          }
        }
      }
    }
  }
`;

const StakingView = ({ classes }: StakingProps) => {
  const { data, loading, error } = useQuery(BITQUERY);
  const [marketcap, setMarketcap] = useState<any | null>(0);
  const [totalsupply, setTotalsupply] = useState(0);
  const [hbscprice, setHbscprice] =  useState<any | null>(0);
  useEffect(() => {
    if(data){
      let totalSupplyvar = data.ethereum.address[0].smartContract.attributes[5].value;
      setTotalsupply(Math.ceil(totalSupplyvar/Math.pow(10, 18)));

      let currPricevar = data.ethereum.dexTrades[0].last_price;
      let hbscpricevar = Number(currPricevar).toFixed(2)
      setHbscprice(Number(hbscpricevar));
      let totalcoins = data.ethereum.transfers[0].minted;
      let Marketcap = hbscprice*totalcoins;
      setMarketcap(Math.ceil(Marketcap))
      console.log(marketcap,hbscprice,totalsupply);
    }
  });
  const [accounts, setAccounts] = useState(['']);
  const [totalStaked, setTotalStaked] = useState(0);
  const [totalInstant, setTotalInstant] = useState(0);
  const [total180, setTotal180] = useState(0);
  const [total270, setTotal270] = useState(0);
  const [total365, setTotal365] = useState(0);
  const [userHBSC, setUserHBSC] = useState(0);
  const [userBUSD, setUserBUSD] = useState(0);

  const [userStakedInstant, setUserStakedInstant] = useState(0);
  const [userStaked180, setUserStaked180] = useState(0);
  const [userStaked270, setUserStaked270] = useState(0);
  const [userStaked365, setUserStaked365] = useState(0);

  const [instantAmount, setInstantAmount] = useState(0);
  const [tier180Amount, setTier180Amount] = useState(0);
  const [tier270Amount, setTier270Amount] = useState(0);
  const [tier365Amount, setTier365Amount] = useState(0);
  
  const [Dividends, setDividends] = useState(0);

  type Staked = {
    Stake0StartTimestamp: number;
    Stake180StartTimestamp: number;
    Stake270StartTimestamp: number;
    Stake365StartTimestamp: number;
  };

  const [startTimes, setStartTimes] = useState<Staked>({
    Stake0StartTimestamp: 0,
    Stake180StartTimestamp: 0,
    Stake270StartTimestamp: 0,
    Stake365StartTimestamp: 0,
  });
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [modalcontnttype, setModalcontnttype] = React.useState('');
  const [modalTier, setModalTier] = useState(0);
  const [modalStaked, setModalStaked] = useState(0);
  const [modalDuration, setModalDuration] = useState(0);
  const [modalTotal, setModalTotal] = useState(0);
  const [modalButton, setModalButton] = useState(
    () => () => console.log('default button'),
  );
  function openModal(type, staked, duration, total, tier) {
    setIsOpen(true);
    setModalcontnttype(type);
    setModalStaked(staked);
    setModalDuration(duration);
    setModalTotal(total);
    setModalTier(tier);
  }

  function closeModal() {
    setIsOpen(false);
  }
  useEffect(() => {
    async function readContract() {
      const newAccounts = await web3.eth.getAccounts();
      setAccounts(newAccounts);

      if (newAccounts[0]) {
        const tot = await stakingContract.methods.totalStaked().call();
        setTotalStaked(tot);
        const tot0 = await stakingContract.methods.total0Staked().call();
        setTotalInstant(tot0);
        const tot180 = await stakingContract.methods.total180Staked().call();
        setTotal180(tot180);
        const tot270 = await stakingContract.methods.total270Staked().call();
        setTotal270(tot270);
        const tot365 = await stakingContract.methods.total365Staked().call();
        setTotal365(tot365);

        const stake0 = await stakingContract.methods
          .token0StakedBalances(newAccounts[0])
          .call();
        setUserStakedInstant(stake0);
        const stake180 = await stakingContract.methods
          .token180StakedBalances(newAccounts[0])
          .call();
        setUserStaked180(stake180);
        const stake270 = await stakingContract.methods
          .token270StakedBalances(newAccounts[0])
          .call();
        setUserStaked270(stake270);
        const stake365 = await stakingContract.methods
          .token365StakedBalances(newAccounts[0])
          .call();
        setUserStaked365(stake365);

        const timestamps = await stakingContract.methods
          .staked(newAccounts[0])
          .call();
        setStartTimes(timestamps);

        const busdDivs = await stakingContract.methods
          .getClaimAmount(newAccounts[0])
          .call();
        setDividends(busdDivs / 1000000000000000000);

        const userAmount = await hbscContract.methods
          .balanceOf(newAccounts[0])
          .call();
        setUserHBSC(userAmount / 1000000000000000000);

        const busdAmount = await busdContract.methods
          .balanceOf(newAccounts[0])
          .call();
        setUserBUSD(busdAmount / 1000000000000000000);
      }
    }
    if (web3.currentProvider) {
      readContract();
    }
  }, [web3.currentProvider]);


  const createStake = async (amount: number, tier: number) => {
    console.log(amount);
    console.log(tier);
    await stakingContract.methods
      .stakeTokens(web3.utils.toBN(amount * 1000000000000000000), tier)
      .send({
        from: accounts[0],
      }).then(() => {
        window.location.reload()
      })
  };

  const unStake = async (tier: number) => {
    await stakingContract.methods.unStakeTokens(tier).send({
      from: accounts[0],
    });
  };

  const onInstantChange = (val: number) => {
    setInstantAmount(val);
  };

  const on180Change = (val: number) => {
    setTier180Amount(val);
  };

  const on270Change = (val: number) => {
    setTier270Amount(val);
  };

  const on365Change = (val: number) => {
    setTier365Amount(val);
  };

  const onApproveClick = async () => {
    console.log(accounts[0]);
    let approveContract;
    await hbscContract.methods
      .approve(
        stakingAddress,
        web3.utils.toWei(web3.utils.toBN(Number.MAX_SAFE_INTEGER - 1)),
      )
      .send({ from: accounts[0] });
    // if (pageName === 'reservation') {
    //   await busdContract.methods
    //     .approve(
    //       reservationAddress,
    //       web3.utils.toWei(web3.utils.toBN(Number.MAX_SAFE_INTEGER - 1)),
    //     )
    //     .send({ from: accounts[0] });
    // }
    // if (pageName == 'staking') {
    //   await hbscContract.methods
    //     .approve(
    //       stakingAddress,
    //       web3.utils.toWei(web3.utils.toBN(Number.MAX_SAFE_INTEGER - 1)),
    //     )
    //     .send({ from: accounts[0] });
    // }
  };

  const getTimeRemaining = (startTime: number, length: number) => {
    if (startTime == 0) {
      return 0;
    }
    const endDate = startTime * 1000 + length * 1000 * 86400;
    const remaining = (endDate - Date.now()) / 86400 / 1000;

    if (remaining < 0) {
      return 0;
    }

    return remaining.toFixed(2);
  };

  const getNewTotal = (current: number, additional: number) => {
    return +(current / 1000000000000000000) + +additional;
  };

  const getPercentageRemaining = (period: number, remaining: number) => {
    if (remaining === 0 || period === 0) {
      return 0;
    }
    let percent = (remaining / period) * 100;
    return percent - (percent % 10);
  };

  const getTotalUserStaked = () => {
    return (
      (+userStakedInstant + +userStaked180 + +userStaked270 + +userStaked365) /
      1000000000000000000
    );
  };

  const claim = async () => {
    await stakingContract.methods.claimDividends().send({ from: accounts[0] })
    .then(() => {
        window.location.reload()
      })
  };

  const getTotalUserHBSC = () => {
    return +userHBSC + +getTotalUserStaked();
  };

  Modal.setAppElement('#root');

  function getDifferenceInDays(date1, date2) {
    const diffInMs = Math.abs(date2 - date1);
    return Math.floor(diffInMs / (1000 * 60 * 60 * 24));
  }

  function getDifferenceInHours(date1, date2) {
    const diffInMs = Math.abs(date2 - date1) / 1000;
    const hours = Math.floor(diffInMs / 3600) % 24;
    return hours;
  }

  function getDifferenceInMinutes(date1, date2) {
    const diffInMs = Math.abs(date2 - date1) / 1000;
    const minutes = Math.floor(diffInMs / 60) % 60;
    return minutes;
  }

  function getDifferenceInSeconds(date1, date2) {
    const diffInMs = Math.abs(date2 - date1) / 1000;
    const seconds = Math.floor(diffInMs) % 60;
    return seconds;
  }

  var date2 = new Date('2021-11-22T21:57:00.000+00:00').getTime();
  var date1 = new Date();
  const [starttime, setStarttime] = useState('00h 00m 00s');
  useEffect(() => {
    setInterval(() => {
      date1 = new Date();
      setStarttime(
        `${getDifferenceInHours(date1, date2)}h ${getDifferenceInMinutes(
          date1,
          date2,
        )}m ${getDifferenceInSeconds(date1, date2)}s`,
      );
    }, 1000);
  });

  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className={classes.attentionModal}>
          <h3 className="heading">Attention</h3>
          <div className="content">
            {modalcontnttype === 'STAKE'
              ? 'Current stake of ' +
                modalStaked +
                ' HBSC which has completed duration of ' +
                modalDuration +
                ' days will be reset with a new total value of ' +
                modalTotal +
                ' HBSC'
              : getPercentageRemaining(modalDuration, modalTier) +
                ' % of stake remaining. ' +
                modalStaked *
                  (getPercentageRemaining(modalDuration, modalTier) / 100) +
                ' HBSC will be deducted as an early stake penalty'}
          </div>
          <Button color="primary" variant="contained" onClick={modalButton}>
            ACCEPT THE RISK AND CONTINUE TO {modalcontnttype}
          </Button>
          <Button color="primary" variant="text" onClick={() => closeModal()}>
            CANCEL AND RETURN TO THE STAKING PAGE
          </Button>
          <img src={cardBg} className="cardbg" />
        </div>
      </Modal>
      {/* <Header pageName="staking" /> */}
      <Container maxWidth="xl" className={classes.pageTitle}>
        <div className="leftOuter">
          <span className={classes.icon}></span>
          <Typography component="div">
            <h2 className="h2 stakingheadingOuter">
              <div>
                <b>HBSC</b> Staking
              </div>
              <div className="mycountdown">
                Day {getDifferenceInDays(date1, date2)} <span>{starttime}</span>
              </div>
              {/* <Countdown
                date={'2022-01-20T00:00:00.000+01:00'}
                daysInHours={false}
                renderer={renderer}
              /> */}
            </h2>
            {/* <p>Lock your HSBC to earn interest</p>
            <p>1. Approve HBSC, 2.Enter Amount, 3. Click Stake</p> */}
            {/* <p>You have 20 days to reserve your HBSC tokens!</p> */}
            <p>
              Lock your HBSC to earn interest. Contract audited by{' '}
              <a
                href="https://hazecrypto.net/audit/hbsc"
                target="_blank"
                style={{ color: '#F3BA2F' }}
              >
                Haze Crypto
                <i className="fa fa-external-link ml-1" aria-hidden="true"></i>
              </a>
            </p>
          </Typography>
        </div>
      </Container>
      {/* greyedcard was adeed in class below */}
      <Container maxWidth="xl" className={`${classes.cards}`}>
        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormCard isYellow isbigcard>
                <>
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="flex-start"
                  >
                    <Typography variant="h5">
                      <Box color="white" fontWeight="700">
                        My HBSC Wallet
                      </Box>
                    </Typography>
                  </Box>
                  <Box display="flex" flexDirection="column" pt={0.5} mt={2}>
                    <Typography variant="caption">
                      <Box color="white">HBSC in Wallet</Box>
                    </Typography>
                    <Typography variant="h6">
                      <Hbscwallet>
                        <Box
                          lineHeight="normal"
                          color={'#fff'}
                          fontWeight="700"
                          mt={2}
                        >
                          {userHBSC.toFixed(3)}
                        </Box>
                        <ReactTooltip html={true} />
                        <Button
                          color="primary"
                          variant="contained"
                          className="white_button"
                          onClick={onApproveClick}
                        >
                          <p
                            data-type="warning"
                            data-tip="This will approve an unlimited amount. <br/>
                            Please 'edit permission' in metamask if you want to approve specific amount."
                            data-background-color="#F3BA2F"
                            data-text-color="#000"
                            style={{ marginBottom: 0 }}
                          >
                            {/* {pageName === 'staking' ? 'APPROVE HBSC' : 'APPROVE BUSD'} */}
                            APPROVE HBSC
                          </p>
                        </Button>
                        {/* <Button
                          color="primary"
                          variant="contained"
                          onClick={() => claim()}
                        >
                          Approve hbsc
                        </Button> */}
                      </Hbscwallet>
                    </Typography>
                  </Box>
                  <Box borderBottom="2px solid white" mt={2} mb={3}></Box>
                  <Box display="flex" flexDirection="column" pt={0} mt={0}>
                    <Typography variant="caption">
                      <Box color="white">BUSD in Wallet</Box>
                    </Typography>
                    <Typography variant="h6">
                      <Box
                        lineHeight="normal"
                        color={'#fff'}
                        fontWeight="700"
                        mt={2}
                      >
                        {userBUSD.toFixed(3)}
                      </Box>
                    </Typography>
                  </Box>
                </>
              </FormCard>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormCard isbigcard2>
                <>
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                  >
                    <Typography variant="h5">
                      <Box color="white" fontWeight="700">
                        My HBSC Dividends
                      </Box>
                    </Typography>
                  </Box>
                  <Box display="flex" flexDirection="column" pt={0.5} mt={2}>
                    <Typography variant="caption">
                      <Box color="white">Dividend Amount</Box>
                    </Typography>
                    <Typography variant="h6">
                      <Box
                        lineHeight="normal"
                        color={'#F3BA2F'}
                        fontWeight="700"
                        mt={2}
                      >
                        {Dividends}
                      </Box>
                    </Typography>
                  </Box>
                  <Box borderBottom="2px solid grey" mt={2} mb={3}></Box>
                  <Box width="100%" maxWidth="240px" ml="auto" pt="21px">
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() => claim()}
                      fullWidth
                    >
                      CLAIM
                    </Button>
                  </Box>
                </>
              </FormCard>
            </Grid>
          </Grid>
        </Grid>
        <br />
        <Grid container spacing={3}>
          <Grid item sm={12}>
            <FormCard>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="space-between"
                    height="100%"
                  >
                    <Box display="flex" alignItems="flex-end">
                      <Typography variant="h6">
                        <Box lineHeight="normal" color="white" fontWeight="700">
                          Input Details
                        </Box>
                      </Typography>
                      <Typography variant="caption">
                        <Box color={'#FFFFFF'} ml={1}>
                          Unstake when you want for &nbsp;
                          <span style={{ color: '#F3BA2F' }}>0.35</span>% APY
                        </Box>
                      </Typography>
                    </Box>
                    <Box mt={3} width="100%">
                      <OutlinedInput
                        icon={
                          <Box mr={1}>
                            <img src={walletIcon} height="20"></img>
                          </Box>
                        }
                        label="Staking Amount"
                        placeholder="Staking Amount"
                        actionTitle="HBSC"
                        onChangeFunc={(e) => onInstantChange(e.target.value)}
                        actionFunc={() => console.log('action clicked')}
                      />
                    </Box>
                  </Box>
                </Grid>
                <Grid item sm={4} container alignItems="flex-end">
                  <Grid container item xs={6} sm={4} justify="flex-end">
                    <Box
                      display="flex"
                      flexDirection="column"
                      pt={0.5}
                      ml={1.5}
                    >
                      <Typography variant="caption">
                        <Box color={'#FFFFFF'}>Staked Amount</Box>
                      </Typography>
                      <Typography variant="h6">
                        <Box
                          lineHeight="normal"
                          color="#F3BA2F"
                          fontWeight="700"
                          mt={0.5}
                        >
                          {userStakedInstant / 1000000000000000000}
                        </Box>
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={6} sm={8}></Grid>
                </Grid>
                <Grid
                  item
                  sm={2}
                  container
                  direction="column"
                  justify="space-between"
                  alignItems="center"
                  className="leftbuttons"
                >
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => {
                      // createStake(instantAmount, 0);
                      createStake(instantAmount, 0);
                    }}
                    fullWidth
                  >
                    CREATE STAKE
                  </Button>
                  <Button
                    color="secondary"
                    variant="contained"
                    className="black_button"
                    onClick={() => {
                      unStake(0);
                    }}
                    fullWidth
                  >
                    UNSTAKE HBSC
                  </Button>
                </Grid>
              </Grid>
            </FormCard>
          </Grid>
          <Grid item sm={12}>
            <FormCard>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="space-between"
                    height="100%"
                  >
                    <Box display="flex" alignItems="flex-end">
                      <Typography variant="h6">
                        <Box lineHeight="normal" color="white" fontWeight="700">
                          180 Days Stake
                        </Box>
                      </Typography>
                      <Typography variant="caption">
                        <Box color={'#FFFFFF'} ml={1}>
                          Receive&nbsp;
                          <span style={{ color: '#F3BA2F' }}>0.525</span>% APY
                        </Box>
                      </Typography>
                    </Box>
                    <Box mt={3} width="100%">
                      <OutlinedInput
                        icon={
                          <Box mr={1}>
                            <img src={walletIcon} height="20"></img>
                          </Box>
                        }
                        label="Staking Amount"
                        placeholder="Staking Amount"
                        actionTitle="HBSC"
                        onChangeFunc={(e) => on180Change(e.target.value)}
                        actionFunc={() => console.log('action clicked')}
                      />
                    </Box>
                  </Box>
                </Grid>
                <Grid item sm={4} container alignItems="flex-end">
                  <Grid item container xs={6} sm={4} justify="flex-end">
                    <Box
                      display="flex"
                      flexDirection="column"
                      pt={0.5}
                      borderRight="2px solid grey"
                      ml={1.5}
                    >
                      <Typography variant="caption">
                        <Box color={'#FFFFFF'}>Staked Amount</Box>
                      </Typography>
                      <Typography variant="h6">
                        <Box
                          lineHeight="normal"
                          color={'#F3BA2F'}
                          fontWeight="700"
                          mt={0.5}
                        >
                          {userStaked180 / 1000000000000000000}
                        </Box>
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={6} sm={8}>
                    <Box
                      display="flex"
                      flexDirection="column"
                      justifyContent="flex-end"
                      height="calc(100% - 4px)"
                      ml={4}
                    >
                      <Typography variant="caption">
                        <Box color={'#FFFFFF'}>Time until end of Stake</Box>
                      </Typography>
                      <Typography variant="h6">
                        <Box
                          lineHeight="normal"
                          color={'#F3BA2F'}
                          fontWeight="700"
                          mt={0.5}
                          display="flex"
                          alignItems="center"
                        >
                          {getTimeRemaining(
                            startTimes.Stake180StartTimestamp,
                            180,
                          )}
                          &nbsp;&nbsp;
                          <Typography variant="subtitle1">
                            <Box
                              fontWeight="700"
                              color="white"
                              lineHeight="normal"
                            >
                              DAYS
                            </Box>
                          </Typography>
                        </Box>
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
                <Grid
                  item
                  sm={2}
                  container
                  direction="column"
                  justify="space-between"
                  alignItems="center"
                >
                  <ReactTooltip html={true} />
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => {
                      openModal(
                        'STAKE',
                        userStaked180 / 1000000000000000000,
                        getTimeRemaining(
                          startTimes.Stake180StartTimestamp,
                          180,
                        ),
                        getNewTotal(userStaked180, tier180Amount),
                        180,
                      );
                      setModalButton(
                        () => () => createStake(tier180Amount, 180),
                      );
                    }}
                    fullWidth
                  >
                    <p
                      style={{ margin: 0 }}
                      data-type="warning"
                      data-tip={stakeOnTopWarning}
                      data-background-color="#F3BA2F"
                      data-text-color="#000"
                    >
                      CREATE STAKE
                    </p>
                  </Button>
                  <ReactTooltip html={true} />
                  <Button
                    color="secondary"
                    variant="contained"
                    className="black_button"
                    onClick={() => {
                      openModal(
                        'UNSTAKE',
                        userStaked180 / 1000000000000000000,
                        getTimeRemaining(
                          startTimes.Stake180StartTimestamp,
                          180,
                        ),
                        getNewTotal(userStaked180, tier180Amount),
                        180,
                      );
                      setModalButton(() => () => unStake(180));
                    }}
                    fullWidth
                  >
                    <p
                      style={{ margin: 0 }}
                      data-type="warning"
                      data-tip={emergencyUnstakeWarning}
                      data-background-color="#F3BA2F"
                      data-text-color="#000"
                    >
                      UNSTAKE HBSC
                    </p>
                  </Button>
                </Grid>
              </Grid>
            </FormCard>
          </Grid>
          <Grid item sm={12}>
            <FormCard>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="space-between"
                    height="100%"
                  >
                    <Box display="flex" alignItems="flex-end">
                      <Typography variant="h6">
                        <Box lineHeight="normal" color="white" fontWeight="700">
                          270 Days Stake
                        </Box>
                      </Typography>
                      <Typography variant="caption">
                        <Box color={'#FFFFFF'} ml={1}>
                          Receive&nbsp;
                          <span style={{ color: '#F3BA2F' }}>0.875</span>% APY
                        </Box>
                      </Typography>
                    </Box>
                    <Box mt={3} width="100%">
                      <OutlinedInput
                        icon={
                          <Box mr={1}>
                            <img src={walletIcon} height="20"></img>
                          </Box>
                        }
                        label="Staking Amount"
                        placeholder="Staking Amount"
                        actionTitle="HBSC"
                        onChangeFunc={(e) => on270Change(e.target.value)}
                        actionFunc={() => console.log('action clicked')}
                      />
                    </Box>
                  </Box>
                </Grid>
                <Grid item sm={4} container alignItems="flex-end">
                  <Grid item container xs={6} sm={4} justify="flex-end">
                    <Box
                      display="flex"
                      flexDirection="column"
                      pt={0.5}
                      borderRight="2px solid grey"
                      ml={1.5}
                    >
                      <Typography variant="caption">
                        <Box color={'#FFFFFF'}>Staked Amount</Box>
                      </Typography>
                      <Typography variant="h6">
                        <Box
                          lineHeight="normal"
                          color={'#F3BA2F'}
                          fontWeight="700"
                          mt={0.5}
                        >
                          {userStaked270 / 1000000000000000000}
                        </Box>
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={6} sm={8}>
                    <Box
                      display="flex"
                      flexDirection="column"
                      justifyContent="flex-end"
                      height="calc(100% - 4px)"
                      ml={4}
                    >
                      <Typography variant="caption">
                        <Box color={'#FFFFFF'}>Time until end of Stake</Box>
                      </Typography>
                      <Typography variant="h6">
                        <Box
                          lineHeight="normal"
                          color={'#F3BA2F'}
                          fontWeight="700"
                          mt={0.5}
                          display="flex"
                          alignItems="center"
                        >
                          {getTimeRemaining(
                            startTimes.Stake270StartTimestamp,
                            270,
                          )}
                          &nbsp;&nbsp;
                          <Typography variant="subtitle1">
                            <Box
                              fontWeight="700"
                              color="white"
                              lineHeight="normal"
                            >
                              DAYS
                            </Box>
                          </Typography>
                        </Box>
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
                <Grid
                  item
                  sm={2}
                  direction="column"
                  justify="space-between"
                  alignItems="center"
                  container
                >
                  <ReactTooltip html={true} />
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => {
                      openModal(
                        'STAKE',
                        userStaked270 / 1000000000000000000,
                        getTimeRemaining(
                          startTimes.Stake270StartTimestamp,
                          270,
                        ),
                        getNewTotal(userStaked270, tier270Amount),
                        270,
                      );
                      setModalButton(
                        () => () => createStake(tier270Amount, 270),
                      );
                    }}
                    fullWidth
                  >
                    <p
                      style={{ margin: 0 }}
                      data-type="warning"
                      data-tip={stakeOnTopWarning}
                      data-background-color="#F3BA2F"
                      data-text-color="#000"
                    >
                      CREATE STAKE
                    </p>
                  </Button>
                  <ReactTooltip html={true} />
                  <Button
                    color="secondary"
                    variant="contained"
                    className="black_button"
                    onClick={() => {
                      openModal(
                        'UNSTAKE',
                        userStaked270 / 1000000000000000000,
                        getTimeRemaining(
                          startTimes.Stake270StartTimestamp,
                          270,
                        ),
                        getNewTotal(userStaked270, tier270Amount),
                        270,
                      );
                      setModalButton(() => () => unStake(270));
                    }}
                    fullWidth
                  >
                    <p
                      style={{ margin: 0 }}
                      data-type="warning"
                      data-tip={emergencyUnstakeWarning}
                      data-background-color="#F3BA2F"
                      data-text-color="#000"
                    >
                      UNSTAKE HBSC
                    </p>
                  </Button>
                </Grid>
              </Grid>
            </FormCard>
          </Grid>
          <Grid item sm={12}>
            <FormCard>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="space-between"
                    height="100%"
                  >
                    <Box display="flex" alignItems="flex-end">
                      <Typography variant="h6">
                        <Box lineHeight="normal" color="white" fontWeight="700">
                          365 Days Stake
                        </Box>
                      </Typography>
                      <Typography variant="caption">
                        <Box color={'#FFFFFF'} ml={1}>
                          Receive&nbsp;
                          <span style={{ color: '#F3BA2F' }}>1.75</span>% APY
                        </Box>
                      </Typography>
                    </Box>
                    <Box mt={3} width="100%">
                      <OutlinedInput
                        icon={
                          <Box mr={1}>
                            <img src={walletIcon} height="20"></img>
                          </Box>
                        }
                        label="Staking Amount"
                        placeholder="Staking Amount"
                        actionTitle="HBSC"
                        onChangeFunc={(e) => on365Change(e.target.value)}
                        actionFunc={() => console.log('action clicked')}
                      />
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={4} container alignItems="flex-end">
                  <Grid item xs={6} sm={4} container justify="flex-end">
                    <Box
                      display="flex"
                      flexDirection="column"
                      pt={0.5}
                      borderRight="2px solid grey"
                      ml={1.5}
                    >
                      <Typography variant="caption">
                        <Box color={'#FFFFFF'}>Staked Amount</Box>
                      </Typography>
                      <Typography variant="h6">
                        <Box
                          lineHeight="normal"
                          color={'#F3BA2F'}
                          fontWeight="700"
                          mt={0.5}
                        >
                          {userStaked365 / 1000000000000000000}
                        </Box>
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={6} sm={8}>
                    <Box
                      display="flex"
                      flexDirection="column"
                      justifyContent="flex-end"
                      height="calc(100% - 4px)"
                      ml={4}
                    >
                      <Typography variant="caption">
                        <Box color={'#FFFFFF'}>Time until end of Stake</Box>
                      </Typography>
                      <Typography variant="h6">
                        <Box
                          lineHeight="normal"
                          color={'#F3BA2F'}
                          fontWeight="700"
                          mt={0.5}
                          display="flex"
                          alignItems="center"
                        >
                          {getTimeRemaining(
                            startTimes.Stake365StartTimestamp,
                            365,
                          )}
                          &nbsp;&nbsp;
                          <Typography variant="subtitle1">
                            <Box
                              fontWeight="700"
                              color="white"
                              lineHeight="normal"
                            >
                              DAYS
                            </Box>
                          </Typography>
                        </Box>
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
                <Grid
                  item
                  sm={2}
                  container
                  direction="column"
                  justify="space-between"
                  alignItems="center"
                >
                  <ReactTooltip html={true} />
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => {
                      openModal(
                        'STAKE',
                        userStaked365 / 1000000000000000000,
                        getTimeRemaining(
                          startTimes.Stake365StartTimestamp,
                          365,
                        ),
                        getNewTotal(userStaked365, tier365Amount),
                        365,
                      );
                      setModalButton(
                        () => () => createStake(tier365Amount, 365),
                      );
                    }}
                    fullWidth
                  >
                    <p
                      style={{ margin: 0 }}
                      data-type="warning"
                      data-tip={stakeOnTopWarning}
                      data-background-color="#F3BA2F"
                      data-text-color="#000"
                    >
                      CREATE STAKE
                    </p>
                  </Button>
                  <ReactTooltip html={true} />
                  <Button
                    color="secondary"
                    variant="contained"
                    className="black_button"
                    onClick={() => {
                      openModal(
                        'UNSTAKE',
                        userStaked365 / 1000000000000000000,
                        getTimeRemaining(
                          startTimes.Stake365StartTimestamp,
                          365,
                        ),
                        getNewTotal(userStaked365, tier365Amount),
                        365,
                      );
                      setModalButton(() => () => unStake(365));
                    }}
                    fullWidth
                  >
                    <p
                      style={{ margin: 0 }}
                      data-type="warning"
                      data-tip={emergencyUnstakeWarning}
                      data-background-color="#F3BA2F"
                      data-text-color="#000"
                    >
                      UNSTAKE HBSC
                    </p>
                  </Button>
                </Grid>
              </Grid>
            </FormCard>
          </Grid>{' '}
          <Grid item sm={12}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <FormCard isTileOnRight>
                  <>
                    <Box
                      display="flex"
                      flexDirection="column"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Typography variant="h5">
                        <Box color="white" fontWeight="700" mb="20px">
                          Global Stats
                        </Box>
                      </Typography>
                    </Box>
                    <Grid item sm={12}>
                      <Typography variant="body1" component={'div'}>
                        <Box display="flex" fontWeight="700" mb="6px">
                          <Box color="white" fontWeight="500">
                            Total HBSC Supply{' '}
                          </Box>
                          <Box color="#F3BA2F" ml="auto">
                            {totalsupply}
                          </Box>
                        </Box>
                        <Box display="flex" fontWeight="700" mb="6px">
                          <Box color="white" fontWeight="500">
                            HBSC Price{' '}
                          </Box>
                          <Box color="#F3BA2F" ml="auto">
                            ${hbscprice.toLocaleString('en-US')}
                          </Box>
                        </Box>
                        <Box display="flex" fontWeight="700" mb="6px">
                          <Box color="white" fontWeight="500">
                            Total HBSC Marketcap{' '}
                          </Box>
                          <Box color="#F3BA2F" ml="auto">
                            {/* {(totalStaked / 1000000000000000000).toFixed(3)} */}
                            ${marketcap.toLocaleString('en-US')}
                          </Box>
                        </Box>
                        <Box display="flex" fontWeight="700" mb="6px">
                          <Box color="white" fontWeight="500">
                            Total Staked HBSC Supply{' '}
                          </Box>
                          <Box color="#F3BA2F" ml="auto">
                            {(totalStaked / 1000000000000000000).toFixed(3)}
                          </Box>
                        </Box>
                        <Box display="flex" fontWeight="700" mb="6px">
                          <Box color="white" fontWeight="500">
                            Total Instant Unstake Tier Staked (10%)
                          </Box>
                          <Box color="#F3BA2F" ml="auto">
                            {(totalInstant / 1000000000000000000).toFixed(3)}
                          </Box>
                        </Box>
                        <Box display="flex" fontWeight="700" mb="6px">
                          <Box color="white" fontWeight="500">
                            Total 180 days Staked (15%){' '}
                          </Box>
                          <Box color="#F3BA2F" ml="auto">
                            {(total180 / 1000000000000000000).toFixed(3)}
                          </Box>
                        </Box>
                        <Box display="flex" fontWeight="700" mb="6px">
                          <Box color="white" fontWeight="500">
                            Total 270 days Staked (25%){' '}
                          </Box>
                          <Box color="#F3BA2F" ml="auto">
                            {(total270 / 1000000000000000000).toFixed(3)}
                          </Box>
                        </Box>
                        <Box display="flex" fontWeight="700">
                          <Box color="white" fontWeight="500">
                            Total 365 days Staked (50%){' '}
                          </Box>
                          <Box color="#F3BA2F" ml="auto">
                            {(total365 / 1000000000000000000).toFixed(3)}
                          </Box>
                        </Box>
                      </Typography>
                    </Grid>
                  </>
                </FormCard>
              </Grid>
              <Grid item sm={6} xs={12}>
                <FormCard isTileOnRight>
                  <>
                    <Box
                      display="flex"
                      flexDirection="column"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Typography variant="h5">
                        <Box color="white" fontWeight="700" mb="20px">
                          Personal Stats
                        </Box>
                      </Typography>
                    </Box>
                    <Grid item sm={12}>
                      <Typography variant="body1" component={'div'}>
                        <Box display="flex" fontWeight="600" mb="6px">
                          <Box color="white" fontWeight="500">
                            Your Total HBSC
                          </Box>
                          <Box color="#F3BA2F" ml="auto">
                            {getTotalUserHBSC().toFixed(3)}
                          </Box>
                        </Box>
                        <Box display="flex" fontWeight="700" mb="6px">
                          <Box color="white" fontWeight="500">
                            Your Total Unstaked HBSC
                          </Box>
                          <Box color="#F3BA2F" ml="auto">
                            {userHBSC.toFixed(3)}
                          </Box>
                        </Box>
                        <Box display="flex" fontWeight="700" mb="6px">
                          <Box color="white" fontWeight="500">
                            Your Total Staked HBSC
                          </Box>
                          <Box color="#F3BA2F" ml="auto">
                            {getTotalUserStaked().toFixed(3)}
                          </Box>
                        </Box>
                        <Box display="flex" fontWeight="700" mb="6px">
                          <Box color="white" fontWeight="500">
                            Your Total Instant Unstake Tier Staked (10%){' '}
                          </Box>
                          <Box color="#F3BA2F" ml="auto">
                            {(userStakedInstant / 1000000000000000000).toFixed(
                              3,
                            )}
                          </Box>
                        </Box>
                        <Box display="flex" fontWeight="700" mb="6px">
                          <Box color="white" fontWeight="500">
                            Total 180 days Staked (15%){' '}
                          </Box>
                          <Box color="#F3BA2F" ml="auto">
                            {(userStaked180 / 1000000000000000000).toFixed(3)}
                          </Box>
                        </Box>
                        <Box display="flex" fontWeight="700" mb="6px">
                          <Box color="white" fontWeight="500">
                            Total 270 days Staked (25%){' '}
                          </Box>
                          <Box color="#F3BA2F" ml="auto">
                            {(userStaked270 / 1000000000000000000).toFixed(3)}
                          </Box>
                        </Box>
                        <Box display="flex" fontWeight="700" pb="60px">
                          <Box color="white" fontWeight="500">
                            Total 365 days Staked (50%){' '}
                          </Box>
                          <Box color="#F3BA2F" ml="auto">
                            {(userStaked365 / 1000000000000000000).toFixed(3)}
                          </Box>
                        </Box>
                        {/* <Box display="flex" fontWeight="700" mb="6px">
                          <Box color="white" fontWeight="500">
                            Your % of HBSC Supply{' '}
                          </Box>
                          <Box color="#F3BA2F" ml="auto">
                            Test
                          </Box>
                        </Box> */}
                        {/* <Box display="flex" fontWeight="700" mb="6px">
                          <Box color="white" fontWeight="500">
                            Total HBSC / WBNB Liquidity{' '}
                          </Box>
                          <Box color="#F3BA2F" ml="auto">
                            Test
                          </Box>
                        </Box>
                        <Box display="flex" fontWeight="700">
                          <Box color="white" fontWeight="500">
                            HBSC Price{' '}
                          </Box>
                          <Box color="#F3BA2F" ml="auto">
                            Test
                          </Box>
                        </Box> */}
                      </Typography>
                    </Grid>
                  </>
                </FormCard>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export const Staking = withStyles(styles)(StakingView);

export default Staking;
