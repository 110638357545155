import React from 'react';
import styled from 'styled-components';
import LogoYellow from '../logos/logoYellow';
import PriceTrendUnit from 'components/StakingPage/PriceTrendUnit';
import Button from '@material-ui/core/Button';
import { Link, useHistory } from 'react-router-dom';

const NavbarContainer = styled.div`
  position: relative;
  max-width: 80vw;
  margin: 0 auto;
  margin-top: 20px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media screen and (max-width: 800px) {
    margin-top: 10px;
    flex-direction: column;
    align-items: flex-start;
  }
`;
const DropDown = styled.div`
  .dropdown {
    position: relative;
    display: inline-block;
  }
  .dropdown-content a {
    color: white;
    padding: 0px 24px;
    display: block;
  }
  .dropdown-content a:hover {
    color: black;
    text-decoration: none;
    background: white;
  }
  .dropdown-content {
    display: none;
    border-radius: 20px;
    position: absolute;
    margin-left: -35px;
    background-color: #343a40;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    padding: 12px 0px;
    z-index: 1;
  }

  .dropdown:hover .dropdown-content {
    display: block;
  }
`;
const ButtonOuter = styled.div`
  display: flex;
  align-items: center;
  button {
    a {
      color: #000 !important;
      font-weight: 500;
      text-decoration: none;
      white-space: nowrap;
    }
    margin: 0 4px;
  }
  @media screen and (max-width: 800px) {
    margin-top: 20px;
    width: 100%;
    // display: none;
  }
  @media screen and (max-width: 570px) {
    flex-flow: wrap;
    button {
      margin: 4px;
    ]
  }
`;
function handleClick() {
  window.location.replace('/app');
  // alert('Coming Soon')
}
function Navbar() {
  return (
    <NavbarContainer
      className="wow fadeInDown"
      data-wow-duration="0.5s"
      data-wow-delay="0s"
    >
      <LogoYellow />
      <ButtonOuter>
        <Button color="primary" variant="contained">
          <Link to="/reservation">Reservation</Link>
        </Button>
        <Button color="primary" variant="contained">
          <Link to="/staking">Staking</Link>
        </Button>
        <Button color="primary" variant="contained">
          <a href="/whitepaper">
            Key Features
          </a>
        </Button>
        <DropDown>
          <div className="dropdown">
            <Button color="primary" variant="contained">
              Bonus
            </Button>
            <div className="dropdown-content">
              <a href="#"
              onClick={handleClick}
              >Bonus Round 1</a>
              {/* <a href="#">Bonus Round 1</a> */}
              <a href="" target="_blank">
                Coming Soon
              </a>
              <a href="" target="_blank">
                Coming Soon
              </a>
              <a href="" target="_blank">
                Coming Soon
              </a>
            </div>
          </div>
        </DropDown>
        {/* <Button color="primary" variant="contained">
          <Link to="/comingsoon">Statistics</Link>
        </Button> */}
      </ButtonOuter>
    </NavbarContainer>
  );
}

export default Navbar;
