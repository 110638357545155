import React from 'react';
import styled from 'styled-components';
import TopSection from './topSection';

const PageContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  background-color: #151515;
  font-family: Arial, Helvetica, sans-serif;
`;

function Coming() {
  return (
    <PageContainer>
      <TopSection />
    </PageContainer>
  );
}

export default Coming;
