import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { Viewer } from '@react-pdf-viewer/core';
import { Worker } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import { Plugin,createStore, PluginFunctions, SpecialZoomLevel } from '@react-pdf-viewer/core';
interface CustomZoomPlugin extends Plugin {
    zoomTo(scale: number | SpecialZoomLevel): void;
}
interface StoreProps {
    zoom?(scale: number | SpecialZoomLevel): void;
}
// const store = React.useMemo(() => createStore < StoreProps > {}, []);

// const customZoomPlugin = (): CustomZoomPlugin => {
//     return {
//         zoomTo: (scale: number | SpecialZoomLevel) => {
//             const zoom = store.get('zoom');
//             if (zoom) {
//                 // Zoom to that scale
//                 zoom(scale);
//             }
//         },
//     };
// }
// const customZoomPluginInstance = customZoomPlugin();
// const { zoomTo } = customZoomPluginInstance;

// Your render function
const ModalExample: React.FC = () => {
    const [shown, setShown] = useState(false);
    const defaultLayoutPluginInstance = defaultLayoutPlugin();
    const modalBody = () => (
        <div
            style={{
                backgroundColor: '#fff',
                flexDirection: 'column',

                /* Fixed position */
                left: 0,
                position: 'fixed',
                top: 0,

                border:'3px solid black',
                /* Take full size */
                height: '100%',
                width: '100%',

                /* Displayed on top of other elements */
                zIndex: 99999999999999,
            }}
        >
            <div
                style={{
                    alignItems: 'center',
                    backgroundColor: '#000',
                    color: '#fff',
                    display: 'flex',
                    zIndex: 99999999999999,
                    position:'fixed',
                    width: '100%',
                }}
            >
                
                
            </div>
            <div
                style={{
                    flexGrow: 1,
                    overflow: 'auto',
                }}
            >
                <div
    className="rpv-core__viewer"
    style={{
        border: '1px solid rgba(0, 0, 0, 0.3)',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
    }}
>
    <div
        style={{
            alignItems: 'center',
            backgroundColor: '#eeeeee',
            borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
            display: 'flex',
            justifyContent: 'center',
            padding: '0px',
            // position: 'fixed',
            // zIndex: '9999999999999',
            // width: '98%',
            // top: '2px',
            // left: '5px',
        }}
    >
     <button
                style={{
                    backgroundColor: 'transparent',
                    border: 'none',
                    borderRadius: '.25rem',
                    color: 'inherit',
                    cursor: 'pointer',
                }}
                onClick={() => {setShown(false); } }
            >
                Close
            </button>  
    </div>
    <div
        style={{
            flex: 1,
            overflow: 'hidden',
        }}
    >
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
<Viewer  fileUrl="https://assets-hxy.fra1.cdn.digitaloceanspaces.com/hbsc/wp.pdf" plugins={[defaultLayoutPluginInstance]} />
    
    </Worker>;

    </div>
</div>;

            </div>
        </div>
    );

    return (
        <>
            <button
                style={{
                    backgroundColor: 'transparent',
                    border: 'none',
                    borderRadius: '.25rem',
                    color: 'inherit',
                    cursor: 'pointer',
                }}
                onClick={() => {setShown(true); } }
            >
                WhitePaper
            </button>
            {shown && ReactDOM.createPortal(modalBody(), document.body)}
        </>
    );
};

export default ModalExample;