import React, { useEffect, useState } from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import { BrowserRouter as Router } from 'react-router-dom';
import { Layout } from 'layout/Layout';
import { AuthContextProvider } from 'contexts/AuthContext';
import { authProvider } from './authProvider';
import { theme } from 'theme';
import { ApolloProvider, ApolloClient, InMemoryCache } from '@apollo/client';
// old key BQY7CzmRkQ3P9OIKs7K0VwlrqXLOYvct
const client = new ApolloClient({
  uri: 'https://graphql.bitquery.io',
  headers: {
    'Content-Type': 'application/json',
    'X-API-KEY': 'BQYbQfO7kf3DHyi3wH6nYao94EWCtc7T',
  },
  cache: new InMemoryCache(),
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <ApolloProvider client={client}>
        <AuthContextProvider auth={authProvider}>
          <Router basename={process.env.PUBLIC_URL}>
            <Layout />
          </Router>
        </AuthContextProvider>
      </ApolloProvider>
    </ThemeProvider>
  );
}

export default App;
