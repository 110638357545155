import React, { useContext, useEffect, useCallback, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import {
  withStyles,
  createStyles,
  WithStyles,
  Theme,
} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { Grid, Hidden } from '@material-ui/core';
import logo from 'assets/logo.png';
import styled from 'styled-components';
import { formTypes } from 'components/LoginPage';
import { AuthContext } from 'contexts/AuthContext';
import { MyTheme } from 'theme';
import PriceTrendUnit from 'components/StakingPage/PriceTrendUnit';
import MetaMaskOnboarding from '@metamask/onboarding';
import { useQuery, gql } from '@apollo/client';
import Web3 from 'web3';
import { AbiItem } from 'web3-utils';
import ReactTooltip from 'react-tooltip';
import { useLocation } from 'react-router-dom';
import {
  web3,
  busdContract,
  busdAddress,
  hbscAddress,
  hbscContract,
  stakingAddress,
  reservationAddress,
  reservationContract,
} from '../../contracts';

const UIVersion = 'v0.5';
const ReservationVersion = 'v0.2';
const StakingVersion = 'v0.6';

type ClassKey =
  | 'header'
  | 'headerContainer'
  | 'logo'
  | 'right'
  | 'debugText'
  | 'menu';

const styles = (theme: Theme) => {
  const myTheme = theme as MyTheme;
  return createStyles({
    header: {
      display: 'none',
      // change the display in index.css
      background: '#2E2E2E',
    },
    headerContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      '& ul': {
        marginBottom: 0,
      },
    },
    logo: {
      maxWidth: 140,
      '& a': {
        display: 'block',
        marginRight: 10,
      },
      '& img': {
        display: 'block',
        width: '100%',
        height: 'auto',
      },
    },
    right: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-end',
      paddingRight: '20px',
    },
    debugText: {
      color: 'white',
    },
    menu: {
      display: 'flex',
      flexDirection: 'row',
      listStyle: 'none',
      padding: 0,
      color: '#C6C6C6',
      '& .priceOuter': {
        display: 'flex',
        marginRight: '20px',
      },
      '& li': {
        padding: '25px 10px 0 10px',
        fontSize: '0.9rem',
      },
      '& a': {
        position: 'relative',
        display: 'inline-block',
        padding: '0 20px 26px 20px',
        color: 'inherit',
        textDecoration: 'none',
      },
      '& a:before': {
        content: '""',
        position: 'absolute',
        bottom: 0,
        left: 0,
        width: '100%',
        height: 3,
        borderRadius: 2,
        background: 'transparent',
      },
      '& a:hover': {
        color: myTheme.palette.primary.main,
      },
      '& a.active': {
        color: myTheme.palette.primary.main,
      },
      '& a.active:before': {
        background: myTheme.palette.primary.main,
      },
    },
  });
};

type HeaderProps = WithStyles<ClassKey> & {
  pageName?: string;
  className?: string;
};

const ONBOARD_TEXT = 'INSTALL METAMASK';
const CONNECT_TEXT = 'CONNECT WALLET';
const CONNECTED_TEXT = 'CONNECTED';

const BITQUERY = gql`
  {
    ethereum(network: bsc) {
      dexTrades(
        buyCurrency: { in: "0x1a8abcfdf145379c2443eb7a6e3d127186c867db" }
      ) {
        last_price: maximum(of: block, get: price)
      }
    }
  }
`;

const HeaderView = ({ classes, pageName, className }: HeaderProps) => {
  const { auth, user, loading, setAuthModalOpened } = useContext(AuthContext);
  const history = useHistory();
  pageName = useLocation().pathname.slice(1);

  const { data, error } = useQuery(BITQUERY);
  const [hbscprice, setHbscprice] = useState<any | null>(0);
  useEffect(() => {
    if (data) {
      const currPricevar = data.ethereum.dexTrades[0].last_price;
      const hbscpricevar = Number(currPricevar).toFixed(2);
      setHbscprice(Number(hbscpricevar));
    }
  }, [data]);
  const [buttonText, setButtonText] = React.useState(ONBOARD_TEXT);
  const [isDisabled, setDisabled] = React.useState(false);
  const [accounts, setAccounts] = React.useState(['']);
  const onboarding = React.useRef<MetaMaskOnboarding>();
  const [hbscpricechangeperc, setHbscpricechangeperc] = useState<any | null>(0);

  // useEffect(() => {
  //   let beforedayprice = 0;
  //   async function fetchPrice() {
  //     if (web3.currentProvider != null) {
  //       const poolData = await reservationContract.methods.getAllPools().call();
  //       let totalbusd = 0,
  //         iterator = 0;
  //       const totalhbsc = 200;
  //       let price;
  //       poolData.map((pool, index) => {
  //         if ((pool.endTime as number) * 1000 < Date.now()) {
  //           iterator++;
  //           totalbusd += Math.round(
  //             (pool.totalContribution as number) / 1000000000000000000,
  //           );
  //           beforedayprice = price;
  //           price = totalbusd / (totalhbsc * iterator);
  //         }
  //       });
  //       price = price.toFixed(2);
  //       setHbscprice(price);
  //       fetchPriceChange(price);
  //     }
  //   }

  //   async function fetchPriceChange(currhbscprice) {
  //     const changeperc =
  //       (Number(currhbscprice) - beforedayprice) / Number(currhbscprice);
  //     const ch = changeperc.toFixed(2);
  //     setHbscpricechangeperc(ch);
  //   }

  //   fetchPrice();
  // }, []);

  const onSignUpClick = useCallback((e: React.MouseEvent) => {
    e.preventDefault();
    setAuthModalOpened(formTypes.SIGN_UP);
  }, []);

  const onLogInClick = useCallback((e: React.MouseEvent) => {
    e.preventDefault();
    setAuthModalOpened(formTypes.LOGIN);
  }, []);

  const onLogOutClick = useCallback((e: React.MouseEvent) => {
    e.preventDefault();
    auth.logout('').finally(() => {
      history.push('/');
    });
  }, []);

  const onMetamaskClick = useCallback(async (e: React.MouseEvent) => {
    e.preventDefault();
    console.log('connecting wallet');
    if (MetaMaskOnboarding.isMetaMaskInstalled()) {
      (window as any).ethereum
        .request({ method: 'eth_requestAccounts' })
        .then(async (newAccounts: any) => {
          const chainId = await (window as any).ethereum.request({
            method: 'eth_chainId',
          });
          console.log('chain id of connecting network is', chainId);
          // (window as any).ethereum.on('chainChanged', () => {
          //     onMetamaskClick(e)
          // })
          // (window as any).ethereum.on('accountsChanged', () => {
          //     onMetamaskClick(e)
          // })
          if (chainId == '0x38') {
            console.log('setting wallet and button text')
            setButtonText(CONNECTED_TEXT);
            setAccounts(newAccounts);
            const btn = document.getElementById("connectBtn")
            console.log(btn)
            if (btn !== null) {
              btn.innerText = "Connected"              
            }else{
              console.log('btn is null')
            }
            setDisabled(true);
          } else {
            (window as any).ethereum.request({
              method: 'wallet_switchEthereumChain',
              params: [{ chainId: '0x38' }],
            }).then(() => {
            console.log('setting wallet and button text')
            setButtonText(CONNECTED_TEXT);
            setAccounts(newAccounts);
            setDisabled(true);
            const btn = document.getElementById("connectBtn")
            if (btn !== null) {
              btn.innerText = "Connected"              
            }else{
              console.log('btn is null')
            }
            })
          }
        });
    } else {
      if (onboarding.current) {
        onboarding.current.startOnboarding();
      }
    }
  }, []);

  useEffect(() => {
    if (!onboarding.current) {
      onboarding.current = new MetaMaskOnboarding();
    }
  }, []);

  useEffect(() => {
    if (MetaMaskOnboarding.isMetaMaskInstalled()) {
      if (accounts.length > 0) {
        setButtonText(CONNECT_TEXT);
        setDisabled(true);
        if (onboarding.current) {
          onboarding.current.stopOnboarding();
        }
      } else {
        setButtonText(CONNECT_TEXT);
        setDisabled(false);
      }
    }
    setButtonText(CONNECT_TEXT);
    setDisabled(false);
  }, [accounts]);

  // useEffect(() => {
  //   function handleNewAccounts(newAccounts: any) {
  //     setAccounts(newAccounts);
  //   }
  //   if (MetaMaskOnboarding.isMetaMaskInstalled()) {
  //     (window as any).ethereum
  //       .request({ method: 'eth_requestAccounts' })
  //       .then(handleNewAccounts);
  //     (window as any).ethereum.on('accountsChanged', handleNewAccounts);
  //     return () => {
  //       (window as any).ethereum.on('accountsChanged', handleNewAccounts);
  //     };
  //   }
  // }, []);

  const onApproveClick = async () => {
    console.log(accounts[0]);
    let approveContract;
    if (pageName === 'reservation') {
      await busdContract.methods
        .approve(
          reservationAddress,
          web3.utils.toWei(web3.utils.toBN(Number.MAX_SAFE_INTEGER - 1)),
        )
        .send({ from: accounts[0] });
    }
    if (pageName == 'staking') {
      await hbscContract.methods
        .approve(
          stakingAddress,
          web3.utils.toWei(web3.utils.toBN(Number.MAX_SAFE_INTEGER - 1)),
        )
        .send({ from: accounts[0] });
    }
  };

  const HeaderContainer = styled(Container)`
    justify-content: space-between;
    .menuLinkOuter {
      display: flex;
      justify-content: center;
      margin-bottom: 0px;
    }
    .connectBtn {
      margin-right: auto;
      display: block;
    }
    @media screen and (max-width: 1050px) {
      flex-direction: column !important;
      padding: 14px 0;
      .menuLinkOuter {
        margin-bottom: 20px;
      }
      .connectBtn {
        margin: auto !important;
        display: block;
      }
      .menuinner {
        display: flex;
        flex-direction: column !important;
        padding-right: 0;
      }
      .priceOuter {
        padding: 0;
        margin-top: 20px;
      }
      .menuul {
        display: flex;
        flex-direction: column !important;
      }
    }

    @media screen and (max-width: 768px) {
      .priceOuter {
        flex-direction: column;
      }
    }
  `;

  return (
    <div
      className={`${className} ${classes.header} ${pageName} siteheroheader`}
    >
      {/*
      <Container className={classes.debugText}>
        <p>
          Beta Mainnet: <br /> Token-{hbscAddress} <br />
          Reservation-{reservationAddress}
          <br /> Staking-{stakingAddress}
          <br /> BUSD-{busdAddress}
        </p>
        <p>
          UI Version: {UIVersion} - Reservation Contract: {ReservationVersion} -
          Staking Contract {StakingVersion}
        </p>
        <p> Reservation - 5 mins per pool </p>
        <p> Staking - 30 seconds per day </p>
      </Container>*/}
      <HeaderContainer className={classes.headerContainer} maxWidth="xl">
        {/* <Hidden only="xs">
          <Grid sm={1}>
            <div className={classes.logo}>
              <Link to="/">
                <img src={logo} alt="logo" />
              </Link>
            </div>
          </Grid>
        </Hidden> */}
        {/* <Grid sm={1}>
          <div className={classes.logo}>
            <Link to="/">
              <img src={logo} alt="logo" />
            </Link>
          </div>
        </Grid> */}
        {/* <Grid xs={3} sm={2}>
          <ReactTooltip html={true} />
          <Button color="primary" variant="contained" onClick={onApproveClick}>
            <p
              data-type="warning"
              data-tip="This will approve an unlimited amount. <br/>
              Please 'edit permission' in metamask if you want to approve specific amount."
              data-background-color="#F3BA2F"
              data-text-color="#000"
            >
              {pageName === 'staking' ? 'APPROVE HBSC' : 'APPROVE BUSD'}
            </p>
          </Button>
        </Grid> */}
        <div className={classes.logo}>
          <Link to="/">
            <img src={logo} alt="logo" />
          </Link>
        </div>
        <div className={`${classes.right} menuinner`}>
          <ul className={`${classes.menu} menuul`}>
            {
              window.innerWidth > 1000 ? (
              <div className="priceOuter">
                <PriceTrendUnit
                  p="5px"
                  pr="20px"
                  label="HBSC TOKEN PRICE"
                  price={`$ ${hbscprice}`}
                  change={`${hbscpricechangeperc} %`}
                />
                {/* <PriceTrendUnit
                  p="5px"
                  label="HBSC DIVIDEND PAID TO LOCKERS"
                  price={`0 HBSC`}
                  change={`0 %`}
                /> */}
              </div>) :  ( '' ) //prettier-ignore
            }
            <div className="menuLinkOuter">
              <Typography component="li">
                <Link
                  to="/staking"
                  className={pageName === 'staking' ? 'active' : ''}
                >
                  Stake
                </Link>
              </Typography>
              <Typography component="li">
                <Link
                  to="/reservation"
                  className={pageName === 'reservation' ? 'active' : ''}
                >
                  Reserve
                </Link>
              </Typography>
              <Typography component="li">
                <Link
                  to="/comingsoon"
                  className={pageName === 'comingsoon' ? 'active' : ''}
                >
                  Statistics
                </Link>
              </Typography>
            </div>
          </ul>
          <ReactTooltip html={true} />
          <Button
            color="primary"
            variant="contained"
            onClick={onMetamaskClick}
            className="connectBtn"
          >
            <p
              style={{ margin: 0, width: '100%' }}
            id="connectBtn"
            data-type="warning"
              data-tip={accounts[0]}
              data-background-color="#F3BA2F"
              data-text-color="#000"
            >
              {/* {accounts} */}
              {accounts[0].length ? "Connected" : "Connect Wallet"}
            </p>
          </Button>
        </div>
      </HeaderContainer>
    </div>
  );
};

export const Header = withStyles(styles)(HeaderView);

export default Header;
