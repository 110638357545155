import React from 'react';
import {
  withStyles,
  createStyles,
  WithStyles,
  Theme,
} from '@material-ui/core/styles';
import styled from 'styled-components';
import LogoWhite from '../../components/LandingPage/logos/logoWhite';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { MyTheme } from 'theme';
import TgIcon from '../../assets/LandingAssets/contact/tg.svg';
import YtIcon from '../../assets/LandingAssets/contact/yt.svg';
import TwitterIcon from '../../assets/LandingAssets/contact/twitter.svg';
import GithubIcon from '../../assets/LandingAssets/contact/github.svg';
import DiscordIcon from '../../assets/LandingAssets/contact/discord.svg';

type ClassKey = 'root';

const styles = (theme: Theme) => {
  const myTheme = theme as MyTheme;
  return createStyles({
    root: {
      background: myTheme.palette.background.default,
      borderTop: 'solid 1px rgba(255, 255, 255, 0.47)',
      flexShrink: 0,
      position: 'relative',
    },
  });
};

type FooterProps = WithStyles<ClassKey>;

const Wrapper = styled.div`
  // min-height: 20vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 1rem 0;
  max-width: 80vw;
  margin: auto;
  @media screen and (min-width: 1200px) {
    flex-direction: row;
    margin: 0 auto;
    justify-content: space-between;
  }

  @media screen and (min-width: 1400px) {
    flex-direction: row;
    margin: 0 auto;
  }
`;

const LogoWrapper = styled.div`
  margin: 1rem auto;
  text-align: center;

  @media screen and (min-width: 1200px) {
    text-align: start;
    margin: 0;
  }
`;

const IdentityWrapper = styled.div`
  @media screen and (min-width: 1200px) {
    // margin-left: 2rem;
  }
`;

const IdentityText = styled.p`
  font-size: 0.8rem;
  color: white;
  text-align: center;
  margin: 0 0 1rem 0;

  @media screen and (min-width: 1200px) {
    margin: 0;
    text-align: start;
    margin: 0.3rem 0 0 0;
  }
`;

const Text = styled.p`
  font-size: 0.8rem;
  color: white;
  margin: 0;
  text-align: center;
  margin-top: 10px;

  // @media screen and (min-width: 1200px) {
  //   margin: 0 2rem 0 auto;
  // }
`;
const Footerversion = styled.div`
  color: #fff;
  text-align: right;
  font-size: 14px;
`;
const SocialWrapper = styled.div`
  // margin-top: 4rem;
  display: flex;
  justify-content: center;
`;
const Footercenter = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  @media screen and (max-width: 1200px) {
    position: relative;
    margin: 14px 0 20px;
    left: unset;
    transform: translateX(0%);
  }
`;

const SocialLink = styled.a`
  transition: all 0.3s ease;
  &:first-child {
    img {
      transform: Scale(2) translateY(-1px);
      transform-origin: center;
    }
  }
  &:nth-child(3) {
    img {
      transform: Scale(1.4) translateY(-1px);
      transform-origin: center;
    }
  }
  &:last-child {
    img {
      transform: Scale(1.1) translateY(0px);
      transform-origin: center;
    }
  }
  &:hover {
    transform: Scale(1.1);
  }
`;

const SocialIcon = styled.img`
  margin: 0 10px;
  height: 1.2rem;
  width: auto;
  &: @media screen and (min-width: 1200px) {
    height: 2rem;
    width: auto;
  }
  @media screen and (max-width: 350px) {
    height: 1rem;
  }
`;

const FooterView = ({ classes }: FooterProps) => {
  return (
    <div className={classes.root}>
      <Wrapper>
        <IdentityWrapper>
          <LogoWrapper>
            <LogoWhite />
          </LogoWrapper>
          <IdentityText>Your Decentralized Banking Partner.</IdentityText>
        </IdentityWrapper>

        <Footercenter>
          <SocialWrapper>
            <SocialLink href="https://t.me/HBSC_IO" target="_blank">
              <SocialIcon src={TgIcon} />
            </SocialLink>
            <SocialLink
              href="https://youtube.com/channel/UCFqMt-naLQNKioZ-7dHue2Q"
              target="_blank"
            >
              <SocialIcon src={YtIcon} />
            </SocialLink>
            <SocialLink href="https://discord.gg/ba4SNDAhRU" target="_blank">
              <SocialIcon src={DiscordIcon} />
            </SocialLink>
            <SocialLink href="https://twitter.com/hbsc_io" target="_blank">
              <SocialIcon src={TwitterIcon} />
            </SocialLink>
            <SocialLink
              href="https://github.com/HXY-EcoSystem/HXY-Development"
              target="_blank"
            >
              <SocialIcon src={GithubIcon} />
            </SocialLink>
          </SocialWrapper>
          <Text>support@hbsc.finance</Text>
        </Footercenter>
        <Footerversion>
          {/* <div>Backend version 1.0a</div> */}
          {/* <div>Frontend version 1.0a</div> */}
        </Footerversion>
      </Wrapper>
    </div>
  );
};

export const Footer = withStyles(styles)(FooterView);

export default Footer;
