import React, { useContext, useEffect, useCallback, useState } from 'react';
import styled from 'styled-components';
import Modal from 'react-modal';
import Navbar from '../../components/LandingPage/navbar';
import BackgroundTop from '../../assets/LandingAssets/top/top-bg.png';
import BackgroundTopMobile from '../../assets/LandingAssets/top/top-bg-mobile.png';
import Astro from '../../assets/LandingAssets/top/astro.png';
import Play from '../../assets/LandingAssets/top/play.svg';
import Playwhite from '../../assets/LandingAssets/top/playwhite.svg';
import Button from '../../components/LandingPage/button';
import Card from '../../components/LandingPage/card';
import StakeImg from '../../assets/LandingAssets/about/stake.png';
import PlanetImg from '../../assets/LandingAssets/top/planet.png';
import BackendImg from '../../assets/LandingAssets/about/backend.png';
import CompoundImg from '../../assets/LandingAssets/about/compound.png';
import { themeColor } from '../../theme';
import { Link } from 'react-router-dom';
import Countdown from 'react-countdown';
import PriceTrendUnit from 'components/StakingPage/PriceTrendUnit';
import { useQuery, gql } from '@apollo/client';

const ContainerHeight = styled.div`
  position: relative;
`;

const ContainerWidth = styled.div`
  position: relative;
  padding: 2rem;
  overflow: hidden;
  z-index: 1;

  @media screen and (min-width: 1200px) {
    padding: 1rem;
  }

  @media screen and (min-width: 1400px) {
    padding: 1rem;
  }
`;

const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(${BackgroundTop});
  // background-size: 120vw;
  background-repeat: no-repeat;
  // background-position: -3rem 10rem;
  background-size: contain;
  background-position: center 15vh;
  @media screen and (min-width: 1200px) {
    background-size: 100vw;
  }
  @media screen and (max-width: 768px) {
    background-image: url(${BackgroundTopMobile});
    background-position: top right;
  }
  // @media screen and (min-width: 1400px) {
  //   background-size: cover;
  // }
`;

const Headline = styled.h1`
  max-width: 80vw;
  margin: 0 auto;
  position: relative;
  margin-top: 2rem;
  color: white;
  font-size: 1.2rem;
  font-weight: 700;
  letter-spacing: 0.05rem;
  line-height: 1.5;
  // height: 4rem;
  overflow-y: visible;

  span {
    font-weight: 700;
    color: ${themeColor.primary};
  }
  @media screen and (max-width: 700px) {
    margin-top: 14rem;
    font-size: 1.8rem;
  }
  @media screen and (min-width: 700px) {
    margin-top: 12rem;
    font-size: 1.8rem;
  }

  @media screen and (min-width: 1200px) {
    font-size: 2.2rem;
    max-width: 47vw;
    margin-left: 9vw;
    margin-top: 13rem;
  }

  @media screen and (min-width: 1400px) {
    margin-top: 18rem;
  }
`;

const ButtonWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 11rem;
  button {
    margin: 0.6rem 0.8rem;
  }

  @media screen and (min-width: 320px) {
    margin-top: 13rem;
  }

  @media screen and (min-width: 360px) {
    margin-top: 2rem;
    flex-flow: wrap;
    justify-content: center;
    // margin-bottom: 120px;
  }

  @media screen and (min-width: 414px) {
  }

  @media screen and (min-width: 525px) {
    margin-left: 5vw;
    // margin-bottom: 40vh;
    justify-content: flex-start;
  }

  @media screen and (min-width: 768px) {
    flex-direction: row;
    margin-top: 2rem;
    button {
      margin: 0 0.8rem;
    }
    margin-left: 6vw;
    margin-bottom: 40vh;
  }

  @media screen and (min-width: 1024px) {
    margin-left: 7vw;
    margin-bottom: 40vh;
  }

  @media screen and (min-width: 1200px) {
    align-items: center;
    margin-left: 8vw;
  }
  @media screen and (max-width: 1200px) {
    button {
      max-width: 140px !important;
      padding: 0.5rem 0;
      font-size: 14px !important;
    }
  }
  @media screen and (max-width: 700px) {
    button {
      margin: 0 0.4rem 0.8rem;
    }
  }
`;

const StyledLink = styled(Link)`
  color: black;
  text-decoration: none;
`;
const Astroimg = styled.img`
  width: 15vh;
  height: auto;
  position: absolute;
  z-index: 99;
  left: 50%;
  top: 40%;
  max-width: 140px;
  transition: all 1s ease;
  @media screen and (max-width: 700px) {
    top: 20%;
    max-width: 80px;
    z-index: 0;
  }
`;
const StyledButton = styled.div`
  background: url(${Play});
  background-size: auto 100%;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 40px;
  width: 186px;
  cursor: pointer;
  background-color: transparent;
  margin-left: 0.8rem;
  position: relative;
  // border-top-right-radius: 8px;
  // border-bottom-right-radius: 8px;
  transition: all 0.3s ease;
  margin-top: 8px;
  a {
    color: #000;
    text-decoration: none;
    width: calc(100% - 30px);
    display: block;
    margin-left: auto;
    text-align: center;
  }
  &:hover {
    // filter: drop-shadow(0px 0px 5px rgba(243, 186, 47, 0.78));
    // transform: scale(1.02);
    transform: translateY(-2px);
    transition: all 0.3s ease;
  }
  @media screen and (max-width: 700px) {
    margin-left: 0;
  }
  @media screen and (min-width: 525px) {
    margin: 0 0.4rem 0.8rem;
  }
  @media screen and (min-width: 700px) {
    margin: 0 0.4rem 0rem;
  }
`;

const StyledButtonWhite = styled(StyledButton)`
  background: url(${Playwhite});
  background-size: auto 100%;
  background-repeat: no-repeat;
`;

const TimerCardOuter = styled.div`
  position: absolute;
  right: 19%;
  top: 24%;
  width: 200px;
  .planet {
    width: 160%;
    max-width: 300px;
    position: absolute;
    left: 50%;
    top: 140px;
    transform: translateX(-50%);
    z-index: 1;
  }
  @media screen and (max-width: 1400px) {
    right: 10%;
    top: 16%;
  }
  @media screen and (max-width: 1400px) {
    right: 10%;
    top: 20%;
    .planet {
      width: 120%;
    }
  }
  @media screen and (max-width: 800px) {
    display: none;
  }
`;
const TimerCard = styled.div`
  background: #fff;
  color: #000;

  padding: 14px;
  display: flex;
  justify-content: center;
  border-radius: 12px;

  .line {
    background: #fff;
    height: 110px;
    width: 2px;
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translate(-50%, 105%);
    z-index: 10;
  }
  .mycountdown {
    text-align: center;
    font-weight: 700;
    font-size: 25px;
  }
`;

const CardsWrapper = styled.div`
  position: relative;
  margin-top: 3rem;
  margin: 0 auto;
  max-width: 80vw;
  @media screen and (orientation: landscape) {
    margin-top: 4rem;
  }

  @media screen and (min-width: 1200px) {
    margin-top: 25rem;
    display: flex;
    justify-content: space-between;
  }

  @media screen and (min-width: 1400px) {
    max-width: 80vw;
    margin-top: 40rem;
  }
  .card {
    transition: all 0.3s ease;
    @media screen and (min-width: 1200px) {
      margin-left: 0;
      margin-right: 0;
    }
    &:hover {
      transform: translateY(-10px);
    }
  }
`;

const PriceOuter = styled.div`
  display: none;
  align-items: center;
  margin-left: 5vw;
  @media screen and (max-width: 768px) {
    display: flex;
    margin-top: 3rem;
    width: 100%;
    margin-bottom: 40vh;
  }
  @media screen and (max-width: 550px) {
    margin-left: 0;
    flex-direction: column;
  }
  @media screen and (max-width: 350px) {
    margin-left: -20px;
    margin-right: -20px;
    width: calc(100vw - 20px);
  }
`;

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '0px',
    background: 'transparent',
    border: 0,
    maxWidth: '700px',
    width: '80%',
    minHeight: '400px',
  },
};

function TopSection() {
  const BITQUERY = gql`
  {
    ethereum(network: bsc) {
      dexTrades(
        buyCurrency: { in: "0x1a8abcfdf145379c2443eb7a6e3d127186c867db" }
      ) {
        last_price: maximum(of: block, get: price)
      }
    }
  }
`;
  const bgref = React.useRef<HTMLImageElement>(null);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [modalUrl, setModalUrl] = React.useState('');
  const { data, error } = useQuery(BITQUERY);
  const [hbscprice, setHbscprice] = useState<any | null>(0);
  const [hbscpricechangeperc, setHbscpricechangeperc] = useState<any | null>(0);

  useEffect(() => {
    if (data) {
      const currPricevar = data.ethereum.dexTrades[0].last_price;
      const hbscpricevar = Number(currPricevar).toFixed(2);
      setHbscprice(Number(hbscpricevar));
    }
  }, [data]);

  function closeModal() {
    setIsOpen(false);
  }
  function onMouseEnterHandler(e) {
    if (bgref.current) {
      if (e.movementX > 50) {
        e.movementX = 50;
      }
      if (e.movementY > 5) {
        e.movementY = 5;
      }
      bgref.current.style.setProperty(
        'transform',
        `translate(${-e.movementX * 3}px ,${-e.movementY * 20}px)`,
      );
    }
  }
  // Random component
  const Completionist = () => <span>You are good to go!</span>;

  // Renderer callback with condition
  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return <Completionist />;
    } else {
      // Render a countdown
      return (
        <div className="mycountdown">
          <span>
            {days}:{hours}:{minutes}:{seconds}
          </span>{' '}
          till launch
        </div>
      );
    }
  };
  Modal.setAppElement('#root');
  return (
    <ContainerHeight onMouseMove={(e) => onMouseEnterHandler(e)}>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <iframe
          width="100%"
          height="420"
          src={modalUrl}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </Modal>
      <Astroimg
        src={Astro}
        ref={bgref}
        className="wow fadeInDown"
        data-wow-duration="0.6s"
        data-wow-delay="0.3s"
      />
      <TimerCardOuter>
        <TimerCard>
          <Countdown
            date={'2021-11-20T00:00:00.000+01:00'}
            daysInHours={false}
            renderer={renderer}
          />
          <div className="line" />
        </TimerCard>
        <img src={PlanetImg} className="planet" />
      </TimerCardOuter>
      <Background />
      <ContainerWidth
        className="wow fadeInDown"
        data-wow-duration="0.6s"
        data-wow-delay="0.8s"
      >
        <Navbar />
        <Headline>
          <span>Stake</span> your crypto assets in the market-leading
          decentralized Bank
        </Headline>
        <ButtonWrapper>
          {/* <Button>
            <StyledLink to="/reservation">Reservation</StyledLink>
          </Button>
          <Button>
            <StyledLink to="/staking">Staking App</StyledLink>
          </Button> */}
          <StyledButton
            onClick={() => {
              setIsOpen(true);
              setModalUrl('https://www.youtube.com/embed/c3mmDQhRpR0');
            }}
          >
            <a>Introduction</a>
          </StyledButton>
          <StyledButton
            onClick={() => {
              setIsOpen(true);
              setModalUrl('https://www.youtube.com/embed/T3ZQFlQGJgs');
            }}
          >
            <a>Reservations</a>
          </StyledButton>

          <StyledButtonWhite
            onClick={() => {
              setIsOpen(true);
              setModalUrl(
                'https://www.youtube.com/embed/cY6wI7EuiIk?start=701',
              );
            }}
          >
            <a>Promo VoskCoin</a>
          </StyledButtonWhite>
          {/* <Button>Telegram</Button> */}
        </ButtonWrapper>
        <PriceOuter>
          {' '}
          <PriceTrendUnit
            py="5px"
            px="10px"
            label="HBSC TOKEN PRICE"
            price={`$ ${hbscprice}`}
            change={`${hbscpricechangeperc} %`}
          />
          <PriceTrendUnit
            py="5px"
            px="10px"
            label="HBSC DIVIDEND PAID TO LOCKERS"
            price="0 HBSC"
            change="+0%"
          />
        </PriceOuter>
        <CardsWrapper
          className="wow fadeInDown"
          data-wow-duration="0.6s"
          data-wow-delay="0.4s"
        >
          <Card
            src={StakeImg}
            className="card"
            title="Staking Protocol"
            text="HBSC is a decentralized staking protocol backed by an Audited code and built for banking efficiency."
          />
          <Card
            src={BackendImg}
            className="card"
            title="Contract Backend"
            text="HBSC is Contract-verifiable. It’s a Decentralized Finance tool that can be used by anyone."
          />
          <Card
            src={CompoundImg}
            className="card"
            title="Compound Interest"
            text="The HBSC token is a very scarce crypto-asset, the embedded tokenomics make it a valuable compounding tool."
          />
        </CardsWrapper>
      </ContainerWidth>
    </ContainerHeight>
  );
}

export default TopSection;
