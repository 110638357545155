import React from 'react';
import styled from 'styled-components';
import Navbar from '../../components/LandingPage/navbar';
import BackgroundTop from '../../assets/LandingAssets/top/bgstar.png';
import BackgroundTopMobile from '../../assets/LandingAssets/top/top-bg-mobile.png';
import Astro from '../../assets/LandingAssets/top/astro.png';
import Button from '../../components/LandingPage/button';
import ModalExample from './App';

import PlanetImg from '../../assets/LandingAssets/top/planet.png';
import BookImg from '../../assets/LandingAssets/top/book.png';

import { themeColor } from '../../theme';

const ContainerHeight = styled.div`
  position: relative;
  min-height: 100vh;
  .planetabout {
    position: absolute;
    width: 20%;
    max-width: 260px;
    min-width: 150px;
    left: 0;
    bottom: 0;
    transform: translate(-50%, 50%);
    z-index: 999999999;
  }
`;

const ContainerWidth = styled.div`
  position: relative;
  padding: 2rem;
  overflow: hidden;
  z-index: 1;

  @media screen and (min-width: 1200px) {
    padding: 1rem;
  }

  @media screen and (min-width: 1400px) {
    padding: 1rem;
  }
`;

const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(${BackgroundTop});
  // background-size: 120vw;
  background-repeat: no-repeat;
  // background-position: -3rem 10rem;
  background-size: contain;
  background-position: center 15vh;
  @media screen and (min-width: 1200px) {
    background-size: 100vw;
  }
  @media screen and (max-width: 768px) {
    background-image: url(${BackgroundTopMobile});
    background-position: top right;
  }
  // @media screen and (min-width: 1400px) {
  //   background-size: cover;
  // }
`;

const Headline = styled.h1`
  max-width: 80vw;
  margin: 0 auto;
  position: relative;
  margin-top: 2rem;
  color: white;
  font-size: 1.2rem;
  font-weight: 700;
  letter-spacing: 0.05rem;
  line-height: 1.5;
  // height: 4rem;
  overflow-y: visible;

  span {
    font-weight: 700;
    color: ${themeColor.primary};
  }
  @media screen and (max-width: 700px) {
    margin-top: 14rem;
    font-size: 1.8rem;
  }
  @media screen and (min-width: 700px) {
    margin-top: 12rem;
    font-size: 1.8rem;
  }

  @media screen and (min-width: 1200px) {
    font-size: 2.2rem;
    max-width: 47vw;
    margin-left: 9vw;
    margin-top: 13rem;
  }

  @media screen and (min-width: 1400px) {
    margin-top: 18rem;
  }
`;

const ButtonWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 11rem;
  button {
    margin: 0.6rem 0.8rem;
  }

  @media screen and (min-width: 320px) {
    margin-top: 13rem;
  }

  @media screen and (min-width: 360px) {
    margin-top: 2rem;
    flex-flow: wrap;
    justify-content: center;
    // margin-bottom: 120px;
  }

  @media screen and (min-width: 414px) {
  }

  @media screen and (min-width: 525px) {
    margin-left: 5vw;
    // margin-bottom: 40vh;
    justify-content: flex-start;
  }

  @media screen and (min-width: 768px) {
    flex-direction: row;
    margin-top: 2rem;
    button {
      margin: 0 0.8rem;
    }
    margin-left: 6vw;
    margin-bottom: 40vh;
  }

  @media screen and (min-width: 1024px) {
    margin-left: 7vw;
    margin-bottom: 40vh;
  }

  @media screen and (min-width: 1200px) {
    align-items: center;
    margin-left: 8vw;
  }
  @media screen and (max-width: 1200px) {
    button {
      max-width: 140px !important;
      padding: 0.5rem 0;
      font-size: 14px !important;
    }
  }
  @media screen and (max-width: 700px) {
    button {
      margin: 0 0.4rem 0.8rem;
    }
  }
`;

const Astroimg = styled.img`
  width: 15vh;
  height: auto;
  position: absolute;
  z-index: 99;
  left: 60%;
  top: 45%;
  max-width: 140px;
  transition: all 1s ease;
  @media screen and (max-width: 700px) {
    top: 20%;
    max-width: 80px;
    z-index: 0;
  }
`;

const TimerCardOuter = styled.div`
  position: absolute;
  right: 15%;
  top: 50%;
  width: 30%;
  .planet {
    width: 200%;
    max-width: 500px;
    position: absolute;
    left: 50%;
    top: 140px;
    transform: translateX(-50%);
    z-index: 1;
  }
  @media screen and (max-width: 1400px) {
    .planet {
      max-width: 400px;
    }
  }
  // @media screen and (max-width: 1400px) {
  //   right: 10%;
  //   top: 20%;
  //   .planet {
  //     width: 120%;
  //   }
  // }
  @media screen and (max-width: 800px) {
    display: none;
  }
`;

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '0px',
    background: 'transparent',
    border: 0,
    maxWidth: '700px',
    width: '80%',
    minHeight: '400px',
  },
};

function TopSection() {
  const bgref = React.useRef<HTMLImageElement>(null);

  function onMouseEnterHandler(e) {
    if (bgref.current) {
      if (e.movementX > 50) {
        e.movementX = 50;
      }
      if (e.movementY > 5) {
        e.movementY = 5;
      }
      bgref.current.style.setProperty(
        'transform',
        `translate(${-e.movementX * 2}px ,${-e.movementY * 10}px)`,
      );
    }
  }

  return (
    <ContainerHeight onMouseMove={(e) => onMouseEnterHandler(e)}>
      <img src={PlanetImg} className="planetabout" />
      <Astroimg
        src={Astro}
        ref={bgref}
        className="wow fadeInDown"
        data-wow-duration="0.6s"
        data-wow-delay="0.3s"
      />
      <TimerCardOuter>
        <img src={BookImg} className="planet" />
      </TimerCardOuter>
      <Background />
      <ContainerWidth
        className="wow fadeInDown"
        data-wow-duration="0.6s"
        data-wow-delay="0.8s"
      >
        <Navbar />
        <Headline>
          View our <span>White Paper</span>
        </Headline>
        <ButtonWrapper>
          <Button>
        <ModalExample/>
          </Button>
          {/* <Button>Telegram</Button> */}
        </ButtonWrapper>
      </ContainerWidth>
    </ContainerHeight>
  );
}

export default TopSection;
